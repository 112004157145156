/**
 * @generated SignedSource<<c1398b03b479838e620d0ea98eaab511>>
 * @relayHash f7f30bd02debb35c680dbd0115559945
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-finding/494.0.0-2025-01-15T20:27:27.721Z/useSharedUrgencySignalsQuery

import { ConcreteRequest, Query } from 'relay-runtime';
export type urgencyType = "CART" | "CONVERSATIONS" | "OFFERS" | "SOLD" | "VIEWS" | "%future added value";
export type useSharedUrgencySignalsQuery$variables = {
  itemIds?: ReadonlyArray<string | null> | null;
};
export type useSharedUrgencySignalsQuery$data = {
  readonly viewer: {
    readonly itemUrgencySignals: ReadonlyArray<{
      readonly itemId: string | null;
      readonly urgencySignals: {
        readonly count: number | null;
        readonly message: string | null;
        readonly urgencyInfoType: urgencyType | null;
      } | null;
    } | null> | null;
  };
};
export type useSharedUrgencySignalsQuery = {
  response: useSharedUrgencySignalsQuery$data;
  variables: useSharedUrgencySignalsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "itemIds"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "itemIds",
      "variableName": "itemIds"
    }
  ],
  "concreteType": "ItemUrgencySignalsType",
  "kind": "LinkedField",
  "name": "itemUrgencySignals",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "itemId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ItemUrgencyInfoType",
      "kind": "LinkedField",
      "name": "urgencySignals",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urgencyInfoType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "message",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useSharedUrgencySignalsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSharedUrgencySignalsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-finding/494.0.0-2025-01-15T20:27:27.721Z/useSharedUrgencySignalsQuery",
    "metadata": {},
    "name": "useSharedUrgencySignalsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "d654badeacc94433ed72583d78f0a349";

export default node;
