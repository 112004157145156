import { defineMessages } from 'dibs-react-intl';

export const abbreviations = defineMessages({
    IN: {
        id: 'abf.measurementUnits.abbreviations.inches',
        defaultMessage: 'IN',
    },
    CM: {
        id: 'abf.measurementUnits.abbreviations.centimeters',
        defaultMessage: 'CM',
    },
});

export const LOCAL_STORAGE_KEY = 'userMeasurementUnit';
