/**
 * @generated SignedSource<<d5af92517d434d145e98bec7a8a15148>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespRefineTopAppliedFilters_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useMeasurementUnit_user">;
  readonly " $fragmentType": "SbRespRefineTopAppliedFilters_user";
};
export type SbRespRefineTopAppliedFilters_user$key = {
  readonly " $data"?: SbRespRefineTopAppliedFilters_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespRefineTopAppliedFilters_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespRefineTopAppliedFilters_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMeasurementUnit_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "621345bc73e2beb83b46f384c1d397a9";

export default node;
