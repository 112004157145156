import * as tracking from 'dibs-tracking';
import {
    SORT,
    CURRENCY,
    FILTER_REFINE_SEARCH,
    SUBJECT_MATTER,
    MEASUREMENTS,
} from '../../../finding/SbSharedRefineMenu/sbSharedRefineMenuConstants';

const { EVENT_RESULTS_PAGE_REFINEMENT, EVENT_RESULT_INTERACTION } = tracking.eventNameConstants;

type TrackEventArguments = Parameters<typeof tracking.trackEvent>;
type TrackEventEventArgument = TrackEventArguments[1];

export const trackFacetClick = (
    trackingTrigger: string | null | undefined,
    isExpanding: boolean
): void => {
    const action = isExpanding ? 'expand facet' : 'collapse facet';
    const trigger = (trackingTrigger ?? '').toLowerCase();
    tracking.trackEvent({
        category: 'results page refinement',
        eventName: EVENT_RESULT_INTERACTION,
        action,
        interaction_type: 'facet',
        label: trigger,
        step_interaction_name: isExpanding ? 'expand' : 'collapse',
        trigger,
    });
};

export const trackSeeMoreClick = (filterName: string): void => {
    const action = 'show more options';
    const trigger = filterName.toLowerCase();
    const label = `${trigger} selected`;
    tracking.trackEvent({
        category: 'results page refinement',
        eventName: EVENT_RESULT_INTERACTION,
        action,
        interaction_type: 'facet',
        label,
        step_interaction_name: 'see more clicked',
        trigger,
    });
};

export const trackPillDeselectClick = (filterName: string, urlLabel: string | null): void => {
    const action = 'filter pill deselected';
    const label = filterName.toLowerCase();
    tracking.trackEvent({
        category: 'results page refinement',
        eventName: EVENT_RESULT_INTERACTION,
        action,
        interaction_type: 'filter',
        label,
        step_interaction_name: action,
        trigger: `${label}${urlLabel ? ` | ${urlLabel.toLowerCase()}` : ''}`,
    });
};

export const trackSearchWithinResults = (event: TrackEventEventArgument): void => {
    const action = 'search within results';
    tracking.trackEvent(
        {
            category: 'results page refinement',
            eventName: EVENT_RESULTS_PAGE_REFINEMENT,
            action,
            interaction_type: action,
        },
        event
    );
};

export const trackFacetSearchClick = (filterName: string): void => {
    tracking.trackEvent({
        eventName: EVENT_RESULT_INTERACTION,
        interaction_type: 'facet',
        step_interaction_name: 'search clicked',
        trigger: filterName,
    });
};

export const trackFilterChange = ({
    filterName,
    filterValue,
    isFromTypeahead,
    isDeselected,
    triggerType,
    ga = {},
    event = undefined,
    isInteractiveEvent,
}: {
    filterName?: string;
    filterValue?: { urlLabel: string };
    isDeselected?: boolean;
    isFromTypeahead?: boolean;
    isInteractiveEvent?: boolean;
    triggerType: unknown;
    ga?: Record<string, unknown>;
    event?: TrackEventEventArgument;
}): void => {
    // Used in filterActions.updateUriRef, but some filters have unique tracking or no tracking at all
    if (!filterName) {
        return;
    }

    let trackingLabel = filterValue?.urlLabel;
    const selectedCopy = isDeselected ? 'deselected' : 'selected';
    const filterTrackingBlacklist = [SORT, CURRENCY];

    // categories should be just l2, l3, l4, etc.
    // refineSearch should be refine search
    let trackingFilterName = filterName
        .replace('category', '')
        .replace(FILTER_REFINE_SEARCH, 'refine search')
        .toLowerCase();

    if (filterName === SUBJECT_MATTER) {
        trackingFilterName = 'subject matter';
    } else if (filterName === MEASUREMENTS) {
        trackingLabel = 'true';
        trackingFilterName = 'dimensions';
    }

    const label = `${trackingLabel}${isFromTypeahead ? ' from modal' : ''}`;

    // don't fire filter tracking for blacklisted filters
    if (!filterTrackingBlacklist.includes(filterName)) {
        tracking.trackEvent(
            {
                category: `results page facet ${selectedCopy}`,
                eventName: EVENT_RESULT_INTERACTION,
                //  This takes the value of the filter heading (except for category which will take (l2, l3, l4)
                action: `${trackingFilterName} ${selectedCopy}`,
                interaction_type: 'filter',
                // This takes the value of the selection chosen (ex. paintings, tables, seating, etc...)
                // When choice is selected from the 'see more' modal, eventLabel will also include 'from modal' (ex. eventLabel: gemstone selected from modal)
                label,
                step_interaction_name: isFromTypeahead ? 'search suggestion clicked' : selectedCopy,
                trigger: `${trackingFilterName} | ${trackingLabel}`,
                additional: {
                    ...(triggerType ? { triggerType } : {}), // only add triggerType if it is truthy
                },
                isInteractiveEvent,
                ...ga,
            },
            event
        );
    }
};
