/**
 * @generated SignedSource<<05829c8bd3c147fd25d1f24100403428>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuyRespTopNav_buyPage$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbRespRefineTopAppliedFilters_itemSearch" | "SbRespSortItems_itemSearch" | "SharedFollowTopButton_itemSearch" | "useMeasurementUnit_itemSearch">;
  readonly " $fragmentType": "BuyRespTopNav_buyPage";
};
export type BuyRespTopNav_buyPage$key = {
  readonly " $data"?: BuyRespTopNav_buyPage$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuyRespTopNav_buyPage">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuyRespTopNav_buyPage",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespRefineTopAppliedFilters_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespSortItems_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMeasurementUnit_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedFollowTopButton_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "15423df2d5d0cfff1335d4ffef204b62";

export default node;
