import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import slugify from 'dibs-slugify/exports/slugify';
import { sessionStorage } from 'dibs-browser-storage';
import { FormattedMessage } from 'dibs-react-intl';
import { trackFacetClick } from '../../../utils/tracking/searchBrowse/filterTracking';
import {
    isPriceFilter,
    isDimensionFilter,
    isFreeShippingFilter,
    constructStatsRange,
    isDeliveredByFilter,
    isLocalPickupFilter,
} from '../sbSharedRefineMenuHelpers';
import { sbSharedRefineMenuMessages } from '../../SbSharedRefineMenu/sbSharedRefineMenuMessages';
import { getPricePillValue } from '../../SbSharedAppliedFilters/sbSharedAppliedFiltersValueHelper';

// components
import { ExpandingArea } from 'dibs-elements/exports/ExpandingArea';

// styles
import styles from './SbSharedRefineMenuFacet.scss';

export const SESSION_STORAGE_KEY = 'facetMenu';

function updateLocalStorage({ isExpanded, storageFacetKey }) {
    const sessionFacetMenu = sessionStorage.getItem(SESSION_STORAGE_KEY) || {};
    sessionStorage.setItem(
        SESSION_STORAGE_KEY,
        Object.assign(sessionFacetMenu, { [storageFacetKey]: isExpanded })
    );
}
function SbSharedRefineMenuFacet({
    title,
    trackingTrigger,
    children,
    appliedFiltersComponent,
    storageFacetKey,
    initiallyExpanded,
    selectedValues,
    measurementUnit,
    titleMessage,
    currency,
    maxStatsRange,
    localizedFilterValue,
}) {
    const [isExpanded, setIsExpanded] = useState(initiallyExpanded);
    useEffect(() => {
        const sessionFacetMenu = sessionStorage.getItem(SESSION_STORAGE_KEY) || {};
        const isOpen = sessionFacetMenu[storageFacetKey];
        if (isOpen !== undefined && isExpanded !== isOpen) {
            setIsExpanded(isOpen);
        }
    }, [storageFacetKey, isExpanded]);
    const onLabelClick = useCallback(() => {
        const newExpandedValue = !isExpanded;
        setIsExpanded(newExpandedValue);
        trackFacetClick(trackingTrigger, newExpandedValue);
        updateLocalStorage({ storageFacetKey, isExpanded: newExpandedValue });
    }, [isExpanded, trackingTrigger, storageFacetKey]);

    const renderAppliedFilters = () => {
        if (!selectedValues.length) {
            return [];
        }

        return selectedValues.map(appliedValue => {
            const displayName = appliedValue.displayName || null;
            const urlLabel = appliedValue.urlLabel || null;
            let value = displayName;
            let filterTitle = titleMessage;
            let dimension = null;

            if (isDimensionFilter(storageFacetKey)) {
                const [min, max] = constructStatsRange(urlLabel, maxStatsRange);
                value = sbSharedRefineMenuMessages.minMaxFormattedMessage({
                    isMobile: true,
                    min,
                    max,
                });
                dimension = (
                    <span className={styles.dimension}>{measurementUnit.toLowerCase()}</span>
                );
            } else if (isPriceFilter(storageFacetKey)) {
                value = getPricePillValue({ currency, displayName, isMobile: true });
            } else if (isDeliveredByFilter(storageFacetKey)) {
                value = (
                    <FormattedMessage
                        id="sb.SbSharedRefineMenuFacet.valueWithinDays"
                        defaultMessage="Within {displayName} Days"
                        values={{ displayName }}
                    />
                );
            } else if (isLocalPickupFilter(storageFacetKey)) {
                value = localizedFilterValue;
            } else if (isFreeShippingFilter(storageFacetKey)) {
                filterTitle = sbSharedRefineMenuMessages.shippingOptions;
                value = sbSharedRefineMenuMessages.shippingFree;
            }

            return (
                <div key={`${storageFacetKey}-${value}`} className={styles.abTestSelectedValue}>
                    {value || filterTitle}
                    {dimension}
                </div>
            );
        });
    };

    return (
        <ExpandingArea
            showArrow
            dataTn={`facet-${slugify(title)}-expanding-area`}
            onClick={onLabelClick}
            label={
                <>
                    <div className={styles.title}>{title}</div>
                    {!isExpanded && renderAppliedFilters()}
                    {appliedFiltersComponent}
                </>
            }
            expanded={isExpanded}
            labelClass={styles.titleWrapper}
            wrapperClass={styles.container}
            expandingAreaClass={styles.facetContent}
        >
            {children}
        </ExpandingArea>
    );
}

SbSharedRefineMenuFacet.defaultProps = {
    initiallyExpanded: true,
    selectedValues: [],
    measurementUnit: '',
    titleMessage: '',
    currency: '',
    maxStatsRange: '',
    localizedFilterValue: '',
};

SbSharedRefineMenuFacet.propTypes = {
    initiallyExpanded: PropTypes.bool,
    title: PropTypes.string.isRequired,
    trackingTrigger: PropTypes.string,
    appliedFiltersComponent: PropTypes.node,
    children: PropTypes.node,
    storageFacetKey: PropTypes.string.isRequired,
    selectedValues: PropTypes.array,
    measurementUnit: PropTypes.string,
    titleMessage: PropTypes.string,
    currency: PropTypes.string,
    maxStatsRange: PropTypes.string,
    localizedFilterValue: PropTypes.string,
};

export { SbSharedRefineMenuFacet };
