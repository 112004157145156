import { decode64 } from '../../utils/decode';
import { filterFalsy } from 'dibs-ts-utils/exports/filterFalsy';
import {
    FRAME_INCLUDED,
    FRAMING_STATUS,
    FRAMING_OPTION_AVAILABLE,
    SALE,
    SELLER_PK,
} from './sbSharedRefineMenuConstants';

export const FILTER_STATS_PRICE = 'price';
export const FILTER_STATS_PRICE_TRADE = 'priceTrade';
export const FILTER_STATS_STARTING_BID = 'starting-bid';
export const FILTER_STATS_WIDTH = 'width';
export const FILTER_STATS_HEIGHT = 'height';
export const FILTER_STATS_DEPTH = 'depth';
export const FILTER_STATS_LENGTH = 'length';

export const FILTER_FREE_SHIPPING = 'shipping-free';

export const DIMENSION_STATS = [
    FILTER_STATS_WIDTH,
    FILTER_STATS_HEIGHT,
    FILTER_STATS_DEPTH,
    FILTER_STATS_LENGTH,
];

export const PRICE_STATS = [
    FILTER_STATS_PRICE,
    FILTER_STATS_PRICE_TRADE,
    FILTER_STATS_STARTING_BID,
];

const MAX_WHITE_GLOVE_PRICE_FILTER = 'max-white-glove-price';
const COLOR_FILTER = 'color';
const DELIVERED_BY_FILTER = 'delivered-by';
const LOCAL_PICKUP_FILTER = 'pickup';
const NEW_ARRIVALS = 'new-arrivals';
const RING_SIZE_FILTER = 'ring-size';
export const SHIPPING_TO_FILTER = 'shipping-to';
const NET_TRADE_PRICE_DISCOUNT = 'netTradePriceDiscount';

const FILTER_VALUE_WILDCARD = '*';

type IsFilterFunc = (filterName: string) => boolean;
// Use this generic type if you want to return filter itself
type Filters<T> = ReadonlyArray<T | null> | null;
// Use this generic type if you return filter values, not filter itself
type FilterValues<T> = ReadonlyArray<{
    name?: string | null;
    values?: ReadonlyArray<T | null> | null;
} | null> | null;

const isFilter = (filterTypes: string[], filterName: string): boolean => {
    return filterTypes.some(filterType => filterType.toLowerCase() === filterName.toLowerCase());
};

export const hasFilterApplied = <T extends { name?: string | null }>(
    appliedFilters: Filters<T>,
    filterNames: string[]
): boolean => {
    return (appliedFilters || []).some(filter => filter?.name && filterNames.includes(filter.name));
};

export const getAppliedFilter = <T extends { name?: string | null }>(
    filters: Filters<T>,
    filterName: string
): T | null => {
    return (filters || []).find(filter => filter?.name === filterName) || null;
};

export const getFilterValues = <T>(
    filters: FilterValues<T>,
    filterName: string
): ReadonlyArray<T> => {
    const appliedFilter = getAppliedFilter(filters, filterName);
    return (appliedFilter?.values || []).filter(filterFalsy);
};

export const getFilterValue = <T>(filters: FilterValues<T>, filterName: string): T | null =>
    getFilterValues(filters, filterName)?.[0] || null;

export const isWhiteGloveFilter: IsFilterFunc = filterName =>
    filterName === MAX_WHITE_GLOVE_PRICE_FILTER;

export const isPriceFilter: IsFilterFunc = filterName => isFilter(PRICE_STATS, filterName);

export const isDimensionFilter: IsFilterFunc = filterName => isFilter(DIMENSION_STATS, filterName);

export const isFreeShippingFilter: IsFilterFunc = filterName => filterName === FILTER_FREE_SHIPPING;

export const isColorFilter: IsFilterFunc = filterName => filterName === COLOR_FILTER;

export const isDeliveredByFilter: IsFilterFunc = filterName => filterName === DELIVERED_BY_FILTER;

export const isLocalPickupFilter: IsFilterFunc = filterName => filterName === LOCAL_PICKUP_FILTER;

export const isNewArrivalsFilter: IsFilterFunc = filterName => filterName === NEW_ARRIVALS;

export const isRingSizeFilter: IsFilterFunc = filterName => filterName === RING_SIZE_FILTER;

export const isNetTradePriceDiscount: IsFilterFunc = filterName =>
    filterName === NET_TRADE_PRICE_DISCOUNT;

export const isSaleFilter: IsFilterFunc = filterName => filterName === SALE;

export const isSellerFilter: IsFilterFunc = filterName => filterName === SELLER_PK;

export const hasPriceFilterApplied = <T extends { name?: string | null }>(
    appliedFilters: Filters<T>
): boolean => hasFilterApplied(appliedFilters, PRICE_STATS);

export const isFramingFilter: IsFilterFunc = filterName =>
    filterName === FRAME_INCLUDED ||
    filterName === FRAMING_STATUS ||
    filterName === FRAMING_OPTION_AVAILABLE;

const getMinValue = (min: string | number): number => {
    const minValue = Number(min) || 1;
    return minValue < 1 ? Math.ceil(minValue) : Math.floor(minValue);
};

/**
 * Min and max have to always be rounded in the same way, for everything except the carat weight filter
 */
export function roundMinMax(min: number, max: number, numeric: true): [number, number];
export function roundMinMax(
    min: string | number,
    max: string | number,
    numeric: true
): [number | '*', number | '*'];
export function roundMinMax(
    min: string | number,
    max: string | number,
    numeric: false
): [string, string];
export function roundMinMax(
    min: string | number,
    max: string | number,
    numeric: boolean
): [number | '*', number | '*'] | [string, string] {
    const minValue = min === FILTER_VALUE_WILDCARD ? min : getMinValue(min);
    const maxValue = max === FILTER_VALUE_WILDCARD ? max : Math.ceil(Number(max));
    if (numeric) {
        return [minValue, maxValue];
    } else {
        const minValueStr = minValue.toString();
        const maxValueStr = maxValue.toString();
        return [minValueStr, maxValueStr];
    }
}

/**
 * Min and max have to be rounded to 1 symbol after the comma for carat weight
 */
export const roundCaratWeightRange = (
    min: string | number,
    max: string | number
): [number, number] => {
    // Controls the count of numbers after the trailing comma. Index of 100 is for two numbers
    const precision = 100;

    let minValue = min === FILTER_VALUE_WILDCARD ? 0 : Number(min) || 0;
    minValue = Math.floor(minValue * precision) / precision;
    let maxValue = Number(max) || minValue;
    maxValue = Math.floor(maxValue * precision) / precision;
    return [minValue, maxValue];
};

/**
 * Expect a valid range string, `[1 TO 6]` or [1.1 TO 2.2]
 */
export const parseRangeDisplayValue = (rangeString?: string | null): [string, string] => {
    const rangeMatch = (rangeString || '').match(/(?:\.?\d)+|\*/g) || [];
    const min = rangeMatch[0] || FILTER_VALUE_WILDCARD;
    const max = rangeMatch[1] || FILTER_VALUE_WILDCARD;
    return [min, max];
};

const handleWildcards = (
    appliedRange: [string, string],
    availableRange: [string, string]
): number[] => {
    return appliedRange.map((value, index) => {
        if (!value || value === FILTER_VALUE_WILDCARD) {
            return Number(availableRange[index]);
        } else {
            return Number(value);
        }
    });
};

/**
 * Given an applied filter value and a secondary available filter value, determines which displayName should be shown
 * to the user.
 */
export function constructStatsRange(
    appliedDisplayValue: string | null | undefined,
    availableDisplayValue: string | null | undefined
): [number, number] {
    const appliedRange = parseRangeDisplayValue(appliedDisplayValue);
    const availableRange = parseRangeDisplayValue(availableDisplayValue);
    const [min, max] = handleWildcards(appliedRange, availableRange);

    return roundMinMax(min, max, true);
}

/**
 * Given an applied filter value and a secondary available filter value, determines which displayName should be shown
 * to the user for the carat weight filter.
 */
export function constructCaratWeightStatsRange(
    appliedDisplayValue: string | null | undefined,
    availableDisplayValue: string | null | undefined
): [number, number] {
    const appliedRange = parseRangeDisplayValue(appliedDisplayValue);
    const availableRange = parseRangeDisplayValue(availableDisplayValue);
    const [min, max] = handleWildcards(appliedRange, availableRange);

    return roundCaratWeightRange(min, max);
}

export const formatRange = (
    linkReference: string | null,
    [min, max]: [string | number | null | undefined, string | number | null | undefined]
): string => {
    let link = decode64(linkReference || '');

    const [minValue, maxValue] = [min, max].map(value => {
        if (value !== 0 && !value) {
            return FILTER_VALUE_WILDCARD;
        } else {
            return value.toString();
        }
    });
    link = link.replace('{min}', minValue);
    link = link.replace('{max}', maxValue);
    return link;
};

export function getInputRangeMinMax<T extends { urlLabel?: string | null }>({
    filter = {},
    filterName = '',
    statKey = '',
    appliedFilters = [],
}: {
    filter?: {
        values?: { stats?: { key?: string | null; values?: string | null }[] | null }[] | null;
    } | null;
    filterName?: string;
    statKey?: string;
    appliedFilters: FilterValues<T>;
}): {
    min: number | null;
    max: number | null;
    availableMin: string | null;
    availableMax: string | null;
} {
    const filterValue = getFilterValue(appliedFilters, filterName);

    const stats = filter?.values?.[0].stats || [];
    const availableStat = stats.find(stat => stat?.key === statKey) || {};

    let availableDisplayValue = '';
    if (!isPriceFilter(filterName)) {
        availableDisplayValue = availableStat.values || '';
    }
    const appliedDisplayValue = filterValue?.urlLabel || '';

    const [min, max] = constructStatsRange(appliedDisplayValue, availableDisplayValue);
    const [parsedMin, parsedMax] = parseRangeDisplayValue(availableDisplayValue);
    const [availableMin, availableMax] = roundMinMax(parsedMin, parsedMax, false);

    return {
        min: Number(min) ? min : null,
        max: Number(max) ? max : null,
        availableMin: Number(availableMin) ? availableMin : null,
        availableMax: Number(availableMax) ? availableMax : null,
    };
}

/**
 * Returns specific value for specific filter name determined by `urlLabel`
 */
export function getFilterValueByUrlLabel<T extends { urlLabel?: string | null } | null>(
    appliedFilters: FilterValues<T>,
    expectedFilterName: string,
    expectedFilterValueUrlLabel: string
): T | null {
    const filterValues = getFilterValues(appliedFilters, expectedFilterName);
    return filterValues.find(value => value?.urlLabel === expectedFilterValueUrlLabel) || null;
}
