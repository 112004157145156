import PropTypes from 'prop-types';
import classnames from 'classnames';
import slugify from 'dibs-slugify/exports/slugify';

// components
import { FormattedNumber } from 'dibs-react-intl';
import { Radio } from 'dibs-elements/exports/Radio';
import { AbsoluteAnchor } from '../../../global/AbsoluteAnchor/AbsoluteAnchor';
import { useSbSelector } from '../../../reducers/useSbSelector';

//styles
import styles from './SbSharedRefineMenuInput.scss';

export function SbSharedRefineMenuInputRadio(props) {
    const {
        text,
        count,
        className,
        value,
        checked,
        onChange,
        linkReference,
        linkable,
        isHidden,
        name,
    } = props;
    const containerClass = classnames(styles.container, className, {
        'is-hidden': isHidden,
    });
    const refetchInFlight = useSbSelector(state => state.filters.refetchInFlight);
    const anchorClass = classnames(styles.anchor, {
        [styles.disabled]: refetchInFlight,
    });

    // put AbsoluteAnchor inside label to prevent click and focus stealing
    const label = (
        <AbsoluteAnchor
            linkReference={linkReference}
            linkable={linkable}
            className={anchorClass}
            onClick={e => e.preventDefault()}
            inert
        >
            {text}
        </AbsoluteAnchor>
    );
    return (
        <div className={containerClass}>
            <Radio
                wrapperClass={styles.inputWrapper}
                labelClass={styles.label}
                value={value}
                checked={checked}
                // radio buttons that are part of the same field require a shared name
                name={name}
                label={label}
                onChange={onChange}
                dataTn={`facet-content-${slugify(text)}`}
                disabled={refetchInFlight}
            />
            {!!count && (
                <span className={styles.count} data-tn={`facet-count-${slugify(text)}`}>
                    <FormattedNumber value={count} />
                </span>
            )}
        </div>
    );
}

SbSharedRefineMenuInputRadio.propTypes = {
    count: PropTypes.number,
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    link: PropTypes.string,
    linkReference: PropTypes.string,
    linkable: PropTypes.bool,
    isHidden: PropTypes.bool,
    name: PropTypes.string.isRequired,
};
