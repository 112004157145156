import { graphql, useFragment } from 'react-relay';
import { useDispatch } from 'react-redux';
import { useState, FC, MouseEvent, KeyboardEvent } from 'react';

import { updateUriRef } from '../../../actions/filterActions';
import { useRingMeasurementType } from '../../hooks/ringSize/useRingMeasurementType';
import { RING_SIZE_FILTER_NAME, RING_SIZE_US } from '../../../constants/ringSize';
import { trackSeeMoreClick } from '../../../utils/tracking/searchBrowse/filterTracking';

import { FormattedMessage } from 'dibs-react-intl';
import { Link } from 'dibs-elements/exports/Link';
import { getFilterValues } from '../sbSharedRefineMenuHelpers';
import { SbSharedRefineMenuRingSizeUnitSelector } from './SbSharedRefineMenuRingSizeUnitSelector';
import styles from './SbSharedRefineMenuRingSize.scss';

import { SbSharedRefineMenuRingSize_filters$key } from './__generated__/SbSharedRefineMenuRingSize_filters.graphql';
import { SbSharedRefineMenuRingSize_itemSearch$key } from './__generated__/SbSharedRefineMenuRingSize_itemSearch.graphql';

const COLLAPSED_RING_SIZE_COUNT = 20;

const filtersFragment = graphql`
    fragment SbSharedRefineMenuRingSize_filters on SearchBrowseFilter @relay(plural: true) {
        name
        totalCount
        localizedFilterName
        values {
            displayName
            urlLabel
            linkReference
            hexCode
            properties {
                mm
                us
            }
        }
    }
`;

const itemSearchFragment = graphql`
    fragment SbSharedRefineMenuRingSize_itemSearch on ItemSearchQueryConnection {
        ...useRingMeasurementType_itemSearch
        ...SbSharedRefineMenuRingSizeUnitSelector_itemSearch
        appliedFilters {
            name
            values {
                urlLabel
                displayName
                linkReference
                properties {
                    mm
                    us
                }
            }
        }
    }
`;

type Props = {
    currency: string;
    filters: SbSharedRefineMenuRingSize_filters$key;
    itemSearch: SbSharedRefineMenuRingSize_itemSearch$key;
};

export const SbSharedRefineMenuRingSize: FC<Props> = ({
    currency,
    filters: filtersRef,
    itemSearch: itemSearchRef,
}) => {
    const filters = useFragment(filtersFragment, filtersRef);
    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);

    const dispatch = useDispatch();
    const [isExpanded, setIsExpanded] = useState(false);
    const [ringMeasurementType] = useRingMeasurementType({ currency, itemSearch });
    const ringSizeFilters = getFilterValues(filters, RING_SIZE_FILTER_NAME);
    const appliedRingSizeFilters = getFilterValues(
        itemSearch?.appliedFilters,
        RING_SIZE_FILTER_NAME
    );
    const selectedRingSizes: Record<string, boolean> = {};
    (appliedRingSizeFilters || []).forEach(filter => {
        if (filter.urlLabel) {
            selectedRingSizes[filter.urlLabel] = true;
        }
    });

    const onClickRingSize = (
        filterValue: (typeof ringSizeFilters)[number],
        isDeselected: boolean,
        event: MouseEvent | KeyboardEvent
    ): void => {
        // Click on selected ring size should lead to deselection event
        dispatch(
            updateUriRef({
                filterName: RING_SIZE_FILTER_NAME,
                filterValue,
                isDeselected,
                event,
                ga: undefined,
                uriRef: '',
            })
        );
    };

    return (
        <>
            <SbSharedRefineMenuRingSizeUnitSelector currency={currency} itemSearch={itemSearch} />
            <div className={styles.ringSizeFilterContainer}>
                {ringSizeFilters.map((filter, index) => {
                    const isSelected = selectedRingSizes[filter.urlLabel || ''];
                    return isExpanded || index < COLLAPSED_RING_SIZE_COUNT ? (
                        <div
                            key={filter.urlLabel}
                            className={`${styles.ringSizeFilterTile} ${
                                isSelected ? styles.selected : ''
                            }`}
                            onClick={e => {
                                onClickRingSize(filter, isSelected, e);
                            }}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    onClickRingSize(filter, isSelected, e);
                                }
                            }}
                            role="button"
                            tabIndex={0}
                        >
                            {ringMeasurementType === RING_SIZE_US
                                ? filter?.properties?.us
                                : filter?.properties?.mm}
                        </div>
                    ) : null;
                })}
            </div>
            <Link
                buyerLinkType="standardLink"
                className={styles.expandFilters}
                onClick={() => {
                    setIsExpanded(!isExpanded);
                    if (!isExpanded) {
                        trackSeeMoreClick(RING_SIZE_FILTER_NAME);
                    }
                }}
                ariaExpanded={isExpanded}
                dataTn={`see-${isExpanded ? 'less' : 'more'}`}
            >
                <FormattedMessage
                    id="sb.SbSharedRefineMenuRingSize.seeMore"
                    defaultMessage={`{isExpanded, select,
                                true {See Less}
                                other {See More}
                            }`}
                    values={{ isExpanded }}
                />
            </Link>
        </>
    );
};
