const SEGMENTS = {
    SELLER: 'dealers',
    EXCLUSIVE: 'exclusive',
} as const;

const VERTICALS = {
    FURNITURE: 'furniture',
    ART: 'art',
    JEWELRY: 'jewelry',
    FASHION: 'fashion',
} as const;

export { SEGMENTS, VERTICALS };
