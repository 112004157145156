import PropTypes from 'prop-types';
import {
    isPriceFilter,
    isDimensionFilter,
    isWhiteGloveFilter,
    isFreeShippingFilter,
    constructStatsRange,
    isColorFilter,
    isDeliveredByFilter,
    isLocalPickupFilter,
    isRingSizeFilter,
    isNetTradePriceDiscount,
} from '../SbSharedRefineMenu/sbSharedRefineMenuHelpers';
import { getPricePillValue } from '../SbSharedAppliedFilters/sbSharedAppliedFiltersValueHelper';
import { sbSharedRefineMenuMessages } from '../SbSharedRefineMenu/sbSharedRefineMenuMessages';
import { RING_SIZE_MM } from '../../constants/ringSize';
import { trackPillDeselectClick } from '../../utils/tracking/searchBrowse/filterTracking';

// components
import { FormattedNumber, FormattedMessage } from 'dibs-react-intl';
import { SearchTag } from 'dibs-elements/exports/SearchTag';

// styles
import styles from './SbRespAppliedFiltersItem.scss';

const whiteGloveFilterMaxValue = filterValue => filterValue && filterValue.displayName;

const minMaxToAppliedFilterValue = (min, max) =>
    sbSharedRefineMenuMessages.minMaxFormattedMessage({ isMobile: false, min, max });

const whiteGloveFilterValue = ({ currency, filterValue }) => {
    const min = (
        <FormattedNumber value={0} style="currency" currency={currency} minimumFractionDigits={0} />
    );

    const max = (
        <FormattedNumber
            value={whiteGloveFilterMaxValue(filterValue)}
            style="currency"
            currency={currency}
            minimumFractionDigits={0}
        />
    );

    return minMaxToAppliedFilterValue(min, max);
};

function getMessageType({ value, filterName }) {
    if (!value) {
        return 'noValue';
    }
    if (isColorFilter(filterName)) {
        return 'color';
    }
    return 'other';
}

export const SbRespAppliedFiltersItem = props => {
    const {
        localizedFilterName,
        filterName,
        filterValue,
        onCloseClick,
        currency,
        availableValues,
        measurementUnit,
        ringMeasurementType,
    } = props;
    const displayName = filterValue?.displayName || null;
    const urlLabel = filterValue?.urlLabel || null;
    let localizedFilterValue = localizedFilterName;
    let value = displayName;
    let dimension = null;

    if (isDeliveredByFilter(filterName)) {
        dimension = (
            <FormattedMessage
                id="sb.SbRespAppliedFiltersItem.dimensionDays"
                defaultMessage=" Days"
            />
        );
    } else if (isLocalPickupFilter(filterName)) {
        value = null;
    } else if (isWhiteGloveFilter(filterName)) {
        value = whiteGloveFilterValue({ currency, filterValue });
    } else if (isPriceFilter(filterName)) {
        value = getPricePillValue({ currency, displayName, isMobile: false });
    } else if (isDimensionFilter(filterName)) {
        const [min, max] = constructStatsRange(urlLabel, availableValues?.[0]?.displayName);
        value = minMaxToAppliedFilterValue(min, max);
        dimension = measurementUnit.toLowerCase();
    } else if (isFreeShippingFilter(filterName)) {
        localizedFilterValue = sbSharedRefineMenuMessages.shippingOptions;
        value = sbSharedRefineMenuMessages.shippingFree;
    } else if (isRingSizeFilter(filterName)) {
        if (ringMeasurementType === RING_SIZE_MM) {
            value = filterValue?.properties?.mm;
            dimension = (
                <FormattedMessage id="sb.SbRespAppliedFiltersItem.mmRingSize" defaultMessage="mm" />
            );
        }
    } else if (isNetTradePriceDiscount(filterName)) {
        dimension = (
            <FormattedMessage
                id="sb.SbRespAppliedFiltersItem.netPriceDiscount"
                defaultMessage="% or more"
            />
        );
    }

    const handleDelete = event => {
        onCloseClick(event);
        trackPillDeselectClick(filterName, urlLabel);
    };

    return (
        <div className={styles.filterItemWrapper}>
            <SearchTag dataTn={`filter-tag-${filterName}`} onDelete={handleDelete} isActive>
                <div className={styles.truncateLabel}>
                    <FormattedMessage
                        id="sb.SbRespAppliedFiltersItem.filter"
                        defaultMessage="{type, select,
                            noValue {{filterName}}
                            color {{filterName}: {colorDot}&nbsp;{filterValue}}
                            other {{filterName}: {filterValue}}
                        }"
                        values={{
                            type: getMessageType({ value, filterName }),
                            filterName: (
                                <span data-tn={`label-${filterName}`}>{localizedFilterValue}</span>
                            ),
                            colorDot: (
                                <span
                                    className={styles.colorDot}
                                    style={{ backgroundColor: filterValue?.hexCode }}
                                />
                            ),
                            filterValue: (
                                <span className={styles.value} data-tn={`value-${filterName}`}>
                                    {value}
                                    {dimension || ''}
                                </span>
                            ),
                        }}
                    />
                </div>
            </SearchTag>
        </div>
    );
};

SbRespAppliedFiltersItem.propTypes = {
    currency: PropTypes.string,
    filterName: PropTypes.string.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    filterValue: PropTypes.object,
    availableValues: PropTypes.array.isRequired,
    measurementUnit: PropTypes.string,
    ringMeasurementType: PropTypes.string,
    localizedFilterName: PropTypes.string,
};
