/**
 * @generated SignedSource<<6fd27ab35e6f9eccf350ce9d2c7272a5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuyRespTopNav_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbRespRefineTopAppliedFilters_user" | "SharedFollowTopButton_user" | "useMeasurementUnit_user">;
  readonly " $fragmentType": "BuyRespTopNav_user";
};
export type BuyRespTopNav_user$key = {
  readonly " $data"?: BuyRespTopNav_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuyRespTopNav_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuyRespTopNav_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMeasurementUnit_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespRefineTopAppliedFilters_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedFollowTopButton_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "e84471a0538410da6c1fa19327f2dba4";

export default node;
