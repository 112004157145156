/**
 * @generated SignedSource<<9543a14ff0304cbcb059773b53ec947c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useMeasurementUnit_user$data = {
  readonly preferences: {
    readonly measurementUnit: string | null;
  } | null;
  readonly " $fragmentType": "useMeasurementUnit_user";
};
export type useMeasurementUnit_user$key = {
  readonly " $data"?: useMeasurementUnit_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"useMeasurementUnit_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useMeasurementUnit_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserPreferences",
      "kind": "LinkedField",
      "name": "preferences",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "measurementUnit",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "bb9bc831b002e81a6d0aa2062e0fbeaa";

export default node;
