import { getAbTestV2 } from 'dibs-buyer-layout/exports/clientABTestV2';
import { AbTestV2Func } from 'dibs-buyer-layout/exports/serverABTestV2';

export const BEST_SELLERS_COLLECTION = 'bestSellersCollection' as const;
export const PERSONALIZED_RERANK_TEST_NAME = 'Personalized-Rerank' as const;
export const USER_ENGAGEMENT_BOOST_TEST_NAME = 'UserEngagementBoost' as const;
export const ITEM_PRICE_IN_SELLABILITY = 'ItemPriceInSellability' as const;
export const TRADE_QUICK_FILTERS = 'TradeQuickFilters' as const;
export const MOVE_SAVE_SEARCH = 'MoveSaveSearch' as const;
export const URGENCY_SIGNALS_SB = 'UrgencySignalsSB';
export const MORE_SPONSORED_ADS = 'MoreSponsoredAds' as const;

export const getPersonalizedRerankAbTestVariant = (): string | undefined =>
    getAbTestV2(PERSONALIZED_RERANK_TEST_NAME)?.variant;

const getUserEngagementBoostAbTest = (getAbTestFunc: AbTestV2Func): string | undefined =>
    getAbTestFunc(USER_ENGAGEMENT_BOOST_TEST_NAME)?.variant;

export const isUserEngagementBoostAbTestVariantOrControl = (getAbTestFunc = getAbTestV2): boolean =>
    !!getUserEngagementBoostAbTest(getAbTestFunc);

export const isUserEngagementBoostAbTestControl = (getAbTestFunc = getAbTestV2): boolean =>
    getUserEngagementBoostAbTest(getAbTestFunc) === 'control';

export const getMoreSponsoredAdsAbTest = (): string | undefined =>
    getAbTestV2(MORE_SPONSORED_ADS)?.variant;
