import { FC, useCallback, useEffect, useState } from 'react';
import { graphql, useFragment, useRelayEnvironment } from 'react-relay';
import { useDispatch } from 'react-redux';
import { useIntl } from 'dibs-react-intl';
import { localStorage } from 'dibs-browser-storage';
import { getFilterValue } from '../../SbSharedRefineMenu/sbSharedRefineMenuHelpers';
import {
    getSupportedMeasurementUnit,
    MEASUREMENT_UNITS,
    MeasurementUnit,
} from 'dibs-intl/exports/units';
import { createNewFilterUriRef } from '../../../utils/uriUtils';
import { useSbSelector } from '../../../reducers/useSbSelector';

/* Actions */
import { updateMeasurementUnit } from '../../../actions/measurementUnitActions';
import { updateUriRef } from '../../../actions/filterActions';

/* Mutation */
import { SharedEditMeasurementUnitPreference as EditMeasurementUnitPreferenceMutation } from '../../../mutations/SharedEditMeasurementUnitPreference';

/* Components */
import { SbSharedRefineMenuInputRadio } from '../../SbSharedRefineMenu/SbSharedRefineMenuInput/SbSharedRefineMenuInputRadio';
/* Constants */
import { LOCAL_STORAGE_KEY, abbreviations } from '../../../constants/measurementUnits';

import { MEASUREMENT_UNIT } from '../../SbSharedRefineMenu/sbSharedRefineMenuConstants';

/* Styles */
import styles from './SbSharedRefineMenuDimensionUnitRadioSelect.scss';

import { SbSharedRefineMenuDimensionUnitRadioSelect_itemSearch$key } from './__generated__/SbSharedRefineMenuDimensionUnitRadioSelect_itemSearch.graphql';
import { SbSharedRefineMenuDimensionUnitRadioSelect_user$key } from './__generated__/SbSharedRefineMenuDimensionUnitRadioSelect_user.graphql';

const itemSearchFragment = graphql`
    fragment SbSharedRefineMenuDimensionUnitRadioSelect_itemSearch on ItemSearchQueryConnection {
        ...useMeasurementUnit_itemSearch
        appliedFilters {
            name
            values {
                urlLabel
            }
        }
    }
`;
const userFragment = graphql`
    fragment SbSharedRefineMenuDimensionUnitRadioSelect_user on User {
        ...useMeasurementUnit_user
        serviceId
        preferences {
            measurementUnit
        }
    }
`;

type Props = {
    itemSearch: SbSharedRefineMenuDimensionUnitRadioSelect_itemSearch$key;
    user: SbSharedRefineMenuDimensionUnitRadioSelect_user$key;
    selectedMeasurementUnit: string;
};

export const SbSharedRefineMenuDimensionUnitRadioSelect: FC<Props> = ({
    itemSearch: itemSearchRef,
    user: userRef,
    selectedMeasurementUnit,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const environment = useRelayEnvironment();

    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);
    const user = useFragment(userFragment, userRef);

    const fetchUser = useSbSelector(state => state.relayVariables.variables.fetchUser);
    const generatedUriRef = useSbSelector(state => state.filters.generatedUriRef) || '';
    const userServiceId = user?.serviceId || '';
    const userProfileMeasurementUnit = user?.preferences?.measurementUnit || '';
    const appliedFilterPreference = getFilterValue(
        itemSearch.appliedFilters,
        MEASUREMENT_UNIT
    )?.urlLabel;

    const [unitPreference, setUnitPreference] = useState('');

    const applyMeasurementUnit = useCallback(
        (measurementUnit: string, event: Event) => {
            const filterName = MEASUREMENT_UNIT;
            const uriRef = createNewFilterUriRef(
                generatedUriRef,
                filterName,
                measurementUnit.toLowerCase()
            );
            dispatch(
                updateUriRef({
                    filterName,
                    filterValue: { urlLabel: measurementUnit },
                    uriRef,
                    event,
                    ga: undefined,
                })
            );
        },
        [dispatch, generatedUriRef]
    );

    const saveMeasurementUnitPreference = useCallback(
        (newMeasurementUnit: MeasurementUnit, onCompleted: () => void = () => {}): void => {
            // save to local storage
            localStorage.setItem(LOCAL_STORAGE_KEY, newMeasurementUnit);
            // commit mutation to update user preferences
            if (userServiceId && userProfileMeasurementUnit !== newMeasurementUnit) {
                EditMeasurementUnitPreferenceMutation.commit(environment, {
                    userServiceId,
                    measurementUnit: newMeasurementUnit,
                    onCompleted,
                    onError: () => {},
                });
            } else {
                onCompleted();
            }
        },
        [environment, userProfileMeasurementUnit, userServiceId]
    );

    const getUserMeasurementUnitPreference = useCallback((): string => {
        let measurementUnit = '';
        /*
         * Figure out correct measurement unit to use. The correct precedence is:
         * 1) logged in user measurement unit preference from service
         * 2) measurement unit from local storage
         */
        if (fetchUser) {
            // if we have a user wait until the user data has been fetched
            if (user) {
                measurementUnit = userProfileMeasurementUnit;
            }
        } else {
            measurementUnit = (localStorage.getItem(LOCAL_STORAGE_KEY) || '') as string;
        }

        return measurementUnit;
    }, [fetchUser, user, userProfileMeasurementUnit]);

    useEffect(() => {
        if (!unitPreference) {
            // measurement-unit query param overrides user preference
            const measurementUnit = appliedFilterPreference || getUserMeasurementUnitPreference();
            setUnitPreference(measurementUnit);

            if (measurementUnit) {
                const newMeasurementUnit = getSupportedMeasurementUnit(
                    measurementUnit.toUpperCase()
                );

                // update measurement unit in the redux store
                dispatch(updateMeasurementUnit(newMeasurementUnit));

                // don't save if measurement-unit comes from query param
                if (!appliedFilterPreference) {
                    saveMeasurementUnitPreference(newMeasurementUnit);
                }
            }
        }
    }, [
        appliedFilterPreference,
        dispatch,
        unitPreference,
        getUserMeasurementUnitPreference,
        saveMeasurementUnitPreference,
    ]);

    const handleMeasurementUnitChange = (
        newMeasurementUnit: MeasurementUnit,
        event: Event
    ): void => {
        // persist change
        saveMeasurementUnitPreference(newMeasurementUnit, () => {
            // apply measurement-unit filter
            applyMeasurementUnit(newMeasurementUnit, event);
            // update measurement unit in the redux store
            dispatch(updateMeasurementUnit(newMeasurementUnit));
        });
    };

    return (
        <div className={styles.container}>
            {MEASUREMENT_UNITS.map(unit => {
                const unitAbbreviations = abbreviations[unit];
                return (
                    <div className={styles.radioInputs} key={unit}>
                        <SbSharedRefineMenuInputRadio
                            value={unit}
                            name={MEASUREMENT_UNIT}
                            linkable={false}
                            onChange={handleMeasurementUnitChange}
                            checked={unit === selectedMeasurementUnit}
                            text={intl.formatMessage(unitAbbreviations)}
                        />
                    </div>
                );
            })}
        </div>
    );
};
