import { graphql, readInlineData } from 'react-relay';
import { trackAbTestV2Variant } from 'dibs-buyer-layout/exports/clientABTestV2';
import { filterFalsy } from 'dibs-ts-utils/exports/filterFalsy';
import {
    BEST_SELLERS_COLLECTION,
    PERSONALIZED_RERANK_TEST_NAME,
    USER_ENGAGEMENT_BOOST_TEST_NAME,
    ITEM_PRICE_IN_SELLABILITY,
    isUserEngagementBoostAbTestControl,
    MOVE_SAVE_SEARCH,
    MORE_SPONSORED_ADS,
    getMoreSponsoredAdsAbTest,
    URGENCY_SIGNALS_SB,
} from '../../utils/abTestHelper';
import { pageTypeConstants } from '../../constants/pageTypeConstants';
import { SortMap } from '../../constants/sbConstants';
import { trackEligibleForRerankEvent } from '../../utils/tracking/searchBrowse/addPersonalizationTrackingData';
import { getEngagedItems } from 'dibs-buyer-layout/exports/engagedItems';

import { SbSharedTrackingFunctions_itemSearch$key } from './__generated__/SbSharedTrackingFunctions_itemSearch.graphql';
import { trackEvent, eventNameConstants } from 'dibs-tracking';
import {
    MIN_ITEMS_COUNT,
    MOBILE_ITEMS_PER_ROW,
    RESP_ITEMS_PER_ROW,
} from '../hooks/useMergedSbAndSponsoredItems';

const itemSearchFragment = graphql`
    fragment SbSharedTrackingFunctions_itemSearch on ItemSearchQueryConnection @inline {
        trackedAbTests
        edges {
            __typename
        }
        sponsored {
            items {
                __typename
            }
            totalSponsoredAdsForPage
        }
    }
`;

type PersonalizedRerankProps = {
    pageType?: string | null;
    sortValue?: string | null;
    isEligibleForRerank?: boolean | null;
};
export const personalizedRerankTracking = ({
    pageType,
    sortValue,
    isEligibleForRerank,
}: PersonalizedRerankProps): void => {
    // This if should be the same as
    // https://github.com/1stdibs/dibs-graphql/blob/release/src/schema/inventory/itemSearch/solrQueries/generateResultQuery.ts#L228
    if (
        sortValue === SortMap.relevance ||
        sortValue === SortMap.buyPageRelevance ||
        (pageType !== pageTypeConstants.BUY && sortValue === SortMap.recommended)
    ) {
        trackAbTestV2Variant(PERSONALIZED_RERANK_TEST_NAME);
    }

    if (isEligibleForRerank) {
        trackEligibleForRerankEvent();
    }
};

export const bestSellersSellabilityTracking = ({
    displayUriRef,
    pageType,
}: {
    displayUriRef: string;
    pageType: string;
}): void => {
    if (pageType === pageTypeConstants.COLLECTION && displayUriRef.includes('/best-sellers/')) {
        trackAbTestV2Variant(BEST_SELLERS_COLLECTION);
    }
};

export const userEngagementBoostTracking = (resultItemIds: string[]): void => {
    const engagedItems = getEngagedItems();

    // SEARCH-3024 debug tracking
    // In AB test control we have sent engagedItems, but did not got `controlEngagedItemIds` in response
    if (isUserEngagementBoostAbTestControl() && engagedItems.length && !resultItemIds.length) {
        trackEvent({
            eventName: eventNameConstants.EVENT_DEBUG,
            interaction_type: 'no control items',
            step_interaction_name: 'control',
        });
    }
    if (!engagedItems.length || !resultItemIds.length) {
        return;
    }
    const engagedItemsMap = engagedItems.reduce(
        (acc, itemId) => ({ ...acc, [itemId]: true }),
        <Record<string, boolean>>{}
    );
    const hasEngagedItemsInResult = resultItemIds.some(
        resultItemId => engagedItemsMap[resultItemId]
    );
    if (hasEngagedItemsInResult) {
        trackAbTestV2Variant(USER_ENGAGEMENT_BOOST_TEST_NAME);
    }
};

export const trackActiveAbTests = (
    itemSearchRef: SbSharedTrackingFunctions_itemSearch$key
): void => {
    const itemSearch = readInlineData(itemSearchFragment, itemSearchRef);
    const trackedAbTests = (itemSearch.trackedAbTests || []).filter(filterFalsy);
    for (const abTest of trackedAbTests) {
        trackAbTestV2Variant(abTest);
    }
};

export const itemPriceInSellabilityTracking = ({
    pageType,
    sortValue,
}: {
    pageType?: string | null;
    sortValue?: string | null;
}): void => {
    if (
        (pageType === pageTypeConstants.SEARCH && sortValue === SortMap.relevance) ||
        sortValue === SortMap.recommended
    ) {
        trackAbTestV2Variant(ITEM_PRICE_IN_SELLABILITY);
    }
};

export const moveSaveSearchButtonTracking = ({
    canFollowSearch,
    pageType,
    isDesktop,
}: {
    canFollowSearch?: boolean | null;
    pageType: string;
    isDesktop: boolean;
}): void => {
    if (
        canFollowSearch &&
        isDesktop &&
        !(
            pageType === pageTypeConstants.CREATOR ||
            pageType === pageTypeConstants.MORE_FROM_SELLER ||
            pageType === pageTypeConstants.DEALER ||
            pageType === pageTypeConstants.SEARCH ||
            pageType === pageTypeConstants.RECOGNIZED_DEALER ||
            pageType === pageTypeConstants.DESIGN
        )
    ) {
        trackAbTestV2Variant(MOVE_SAVE_SEARCH);
    }
};

export const moreSponsoredAdsTracking = ({
    itemSearch: itemSearchRef,
    pageNumber,
    isMobile,
}: {
    itemSearch: SbSharedTrackingFunctions_itemSearch$key;
    pageNumber: number;
    isMobile: boolean | undefined;
}): void => {
    const moreSponsoredAdsAbTestVariant = getMoreSponsoredAdsAbTest();
    if (moreSponsoredAdsAbTestVariant && (pageNumber === 3 || pageNumber === 4)) {
        const itemSearch = readInlineData(itemSearchFragment, itemSearchRef);
        const { edges: items, sponsored } = itemSearch;
        const { items: sponsoredItems, totalSponsoredAdsForPage } = sponsored || {};
        const isEnoughItems = items?.length || 0 >= MIN_ITEMS_COUNT;
        const minSponsoredItems = isMobile ? MOBILE_ITEMS_PER_ROW : RESP_ITEMS_PER_ROW;
        const sponsoredAdsCount =
            moreSponsoredAdsAbTestVariant === 'control'
                ? totalSponsoredAdsForPage ?? 0
                : sponsoredItems?.length ?? 0;

        const isEnoughSponsoredItems = sponsoredAdsCount >= minSponsoredItems;

        if (isEnoughItems && isEnoughSponsoredItems) {
            trackAbTestV2Variant(MORE_SPONSORED_ADS);
        }
    }
};

export const trackUrgencySignals = (): void => {
    trackAbTestV2Variant(URGENCY_SIGNALS_SB);
};
