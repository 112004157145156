/**
 * @generated SignedSource<<9fcbab59353be192e94049879dab4d01>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuInputRangeDimensions_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuDimensionUnitRadioSelect_user" | "useMeasurementUnit_user">;
  readonly " $fragmentType": "SbSharedRefineMenuInputRangeDimensions_user";
};
export type SbSharedRefineMenuInputRangeDimensions_user$key = {
  readonly " $data"?: SbSharedRefineMenuInputRangeDimensions_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuInputRangeDimensions_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedRefineMenuInputRangeDimensions_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMeasurementUnit_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuDimensionUnitRadioSelect_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "bc160ffbcef332cfaf1a6abe33b29191";

export default node;
