/**
 * @generated SignedSource<<d65456f6c7cf855de0f7b15d6a769921>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuFilterArtSize_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuDimensionUnitRadioSelect_user" | "useMeasurementUnit_user">;
  readonly " $fragmentType": "SbSharedRefineMenuFilterArtSize_user";
};
export type SbSharedRefineMenuFilterArtSize_user$key = {
  readonly " $data"?: SbSharedRefineMenuFilterArtSize_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuFilterArtSize_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedRefineMenuFilterArtSize_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMeasurementUnit_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuDimensionUnitRadioSelect_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "b23e3230d4e68735b9f6151a46c9b762";

export default node;
