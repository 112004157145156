import {
    getParamFromUrl,
    isNegotiableOrder,
    isUserComesFromAcceptedOffer,
    isUserComesFromEmail,
} from './checkoutRedirects';
import {
    trackEvent,
    eventNameConstants,
    interactionTypeConstants,
    stepInteractionConstants,
} from 'dibs-tracking';
import { Pane, PANES } from '../constants/checkoutConstants';
import { filterNulls } from 'dibs-ts-utils/exports/filterNulls';
import type { StripeExpressCheckoutElementReadyEvent, ExpressPaymentType } from '@stripe/stripe-js';

type StripeExpressPaymentMethods = NonNullable<
    StripeExpressCheckoutElementReadyEvent['availablePaymentMethods']
>;

//Add GA4 variables eventName and step_type where appropriate
export const PANE_EVENT_DICTIONARY = {
    [PANES.PANE_SHIPPING]: { eventName: 'add_shipping_info', stepType: 'shipping address' },
    [PANES.PANE_DELIVERY]: { eventName: 'add_shipping_info', stepType: 'shipping method' },
    [PANES.PANE_PAYMENT]: { eventName: 'add_payment_info', stepType: 'payment method' },
    [PANES.PANE_REVIEW]: { eventName: 'order_review', stepType: 'order review' },
};

export function getCheckoutTriggerPage(referrer: string): string {
    if (isUserComesFromEmail()) {
        return 'email';
    }

    if (!referrer) {
        return 'unknown';
    }

    const qIndex = referrer.indexOf('?');
    if (qIndex !== -1) {
        // strip query params from referrer url
        // for example, don't treat a pdp with an AB test param that includes checkout as a checkout url
        // https://www.1stdibs.com/id-f_38507712/?abtests=checkout%3Dvariant
        referrer = referrer.slice(0, qIndex);
    }

    if (referrer.indexOf('/favorites/') !== -1) {
        return 'favorites';
    } else if (referrer.indexOf('/inbox/') !== -1) {
        return 'message center';
    } else if (referrer.indexOf('/orders/') !== -1) {
        return 'my orders';
    } else if (referrer.indexOf('/cart/') !== -1) {
        return 'cart';
    } else if (referrer.indexOf('id-') !== -1 && referrer.indexOf('checkout') === -1) {
        return 'pdp';
    } else if (referrer.indexOf('/similar-sold/') !== -1) {
        return 'similar sold items';
    }

    if (referrer.includes('1stdibs.com')) {
        return 'other';
    }

    return 'external';
}

function getCheckoutTriggerFromReferrer(): string {
    const triggerPageType = getParamFromUrl('triggerPage');
    if (triggerPageType) {
        return triggerPageType;
    }

    return getCheckoutTriggerPage(document.referrer);
}

export function getCheckoutTrigger(): string {
    const triggerType = getParamFromUrl('triggerType');

    if (triggerType) {
        return triggerType;
    }

    const triggers = [];
    const trigger = getCheckoutTriggerFromReferrer();
    if (trigger) {
        triggers.push(trigger);

        if (isNegotiableOrder()) {
            triggers.push('make offer');
        } else if (getParamFromUrl('fullPrice') === 'true') {
            triggers.push('purchase at full price');
        } else if (isUserComesFromAcceptedOffer()) {
            triggers.push('accept offer');
        } else {
            triggers.push('purchase');
        }
    }

    return triggers.join('-');
}

type StepInteractionKey = keyof typeof stepInteractionConstants;
type StepInteractionValue = (typeof stepInteractionConstants)[StepInteractionKey];
type CurrentPane = Pane;

function trackPromoInteraction({
    stepInteractionName,
    currentPane,
    promoCode,
}: {
    stepInteractionName: StepInteractionValue;
    currentPane: CurrentPane;
    promoCode?: string;
}): void {
    trackEvent({
        eventName: eventNameConstants.EVENT_PROMO_INTERACTION,
        interaction_type: interactionTypeConstants.INTERACTION_TYPE_CHECKOUT,
        step_interaction_name: stepInteractionName,
        step_type: currentPane,
        trigger: promoCode,
    });
}

export function trackPromoCodeDisplay({ currentPane }: { currentPane: CurrentPane }): void {
    trackPromoInteraction({
        stepInteractionName: stepInteractionConstants.STEP_INTERACTION_DISPLAY,
        currentPane,
    });
}

export function trackPromoCodeClick({ currentPane }: { currentPane: CurrentPane }): void {
    trackPromoInteraction({
        stepInteractionName: stepInteractionConstants.STEP_INTERACTION_CLICK,
        currentPane,
    });
}

export function trackPromoCodeApply({
    successfullyApplied,
    currentPane,
    promoCode,
}: {
    successfullyApplied: boolean;
    currentPane: CurrentPane;
    promoCode: string;
}): void {
    trackPromoInteraction({
        stepInteractionName: successfullyApplied
            ? stepInteractionConstants.STEP_INTERACTION_SUCCESS
            : stepInteractionConstants.STEP_INTERACTION_FAIL,
        currentPane,
        promoCode,
    });
}

export function trackSummaryToggle(isExpanded: boolean, currentStep: CurrentPane): void {
    trackEvent({
        eventName: eventNameConstants.EVENT_CHECKOUT_INTERACTION,
        interaction_type: interactionTypeConstants.INTERACTION_TYPE_ORDER_SUMMARY_DRAWER,
        step_interaction_name: isExpanded
            ? stepInteractionConstants.STEP_INTERACTION_EXPAND
            : stepInteractionConstants.STEP_INTERACTION_COLLAPSE,
        step_type: currentStep,
    });
}

export function trackExpressCheckoutDisplay({
    availablePaymentMethods,
}: {
    availablePaymentMethods: StripeExpressPaymentMethods;
}): void {
    trackEvent({
        eventName: eventNameConstants.EVENT_CHECKOUT_INTERACTION,
        interaction_type: interactionTypeConstants.INTERACTION_TYPE_EXPRESS_CHECKOUT,
        step_interaction_name: stepInteractionConstants.STEP_INTERACTION_EXPRESS_CHECKOUT_DISPLAY,
        trigger: Object.entries(availablePaymentMethods)
            .map(([key, value]) => {
                if (value) {
                    return key.replaceAll('_', '').toLowerCase();
                }
                return null;
            })
            .filter(filterNulls)
            .join('|'),
    });
}

export function trackExpressCheckoutClick({
    expressPaymentType,
}: {
    expressPaymentType: ExpressPaymentType;
}): void {
    trackEvent({
        eventName: eventNameConstants.EVENT_CHECKOUT_INTERACTION,
        interaction_type: interactionTypeConstants.INTERACTION_TYPE_EXPRESS_CHECKOUT,
        step_interaction_name: stepInteractionConstants.STEP_INTERACTION_EXPRESS_CHECKOUT_CLICK,
        trigger: expressPaymentType.replaceAll('_', '').toLowerCase(),
    });
}

export function trackExpressCheckoutExitClick(): void {
    trackEvent({
        eventName: eventNameConstants.EVENT_CHECKOUT_INTERACTION,
        interaction_type: interactionTypeConstants.INTERACTION_TYPE_EXPRESS_CHECKOUT,
        step_interaction_name:
            stepInteractionConstants.STEP_INTERACTION_EXPRESS_CHECKOUT_EXIT_CLICK,
    });
}
