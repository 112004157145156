/**
 * @generated SignedSource<<7feffa0d8ea12f4216d7897e911f4257>>
 * @relayHash 3b78128c01b92f2543adedc35072b612
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-finding/494.0.0-2025-01-15T20:27:27.721Z/SharedEditMeasurementUnitPreferenceMutation

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "CHF" | "DKK" | "EUR" | "GBP" | "JPY" | "MXN" | "NOK" | "SEK" | "USD" | "%future added value";
export type MeasurementUnit = "CM" | "IN" | "%future added value";
export type EditPersonalProfileInput = {
  clientMutationId?: string | null;
  communicationPreferredLocale?: string | null;
  currency?: Currency | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  measurementUnit?: MeasurementUnit | null;
  preferredLocale?: string | null;
  shareActivities?: boolean | null;
  userServiceId: string;
  zipCode?: string | null;
};
export type SharedEditMeasurementUnitPreferenceMutation$variables = {
  input: EditPersonalProfileInput;
};
export type SharedEditMeasurementUnitPreferenceMutation$data = {
  readonly editPersonalProfile: {
    readonly user: {
      readonly preferences: {
        readonly measurementUnit: string | null;
      } | null;
    } | null;
  } | null;
};
export type SharedEditMeasurementUnitPreferenceMutation = {
  response: SharedEditMeasurementUnitPreferenceMutation$data;
  variables: SharedEditMeasurementUnitPreferenceMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "UserPreferences",
  "kind": "LinkedField",
  "name": "preferences",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "measurementUnit",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SharedEditMeasurementUnitPreferenceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditPersonalProfilePayload",
        "kind": "LinkedField",
        "name": "editPersonalProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SharedEditMeasurementUnitPreferenceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditPersonalProfilePayload",
        "kind": "LinkedField",
        "name": "editPersonalProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-finding/494.0.0-2025-01-15T20:27:27.721Z/SharedEditMeasurementUnitPreferenceMutation",
    "metadata": {},
    "name": "SharedEditMeasurementUnitPreferenceMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "aa51e772117a9545039935243730c1cc";

export default node;
