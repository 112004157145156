/**
 * @generated SignedSource<<cfbe06e1d84e5b390e5d1782978732f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type VerticalType = "art" | "fashion" | "furniture" | "jewelry" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuFilters_itemSearch$data = {
  readonly appliedFilters: ReadonlyArray<{
    readonly name: string | null;
    readonly values: ReadonlyArray<{
      readonly displayName: string | null;
      readonly urlLabel: string | null;
    } | null> | null;
  } | null> | null;
  readonly topCategoryL1: VerticalType | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileRefineMenuFilterArtSize_itemSearch" | "SbMobileRefineMenuFilterCaratWeight_itemSearch" | "SbMobileRefineMenuFilterCategory_itemSearch" | "SbMobileRefineMenuFilterDimensions_itemSearch" | "SbMobileRefineMenuFilterPrice_itemSearch" | "SbMobileRefineMenuFilterStoneCut_itemSearch" | "SbRespRefineMenuFilterArtSize_itemSearch" | "SbRespRefineMenuFilterCaratWeight_itemSearch" | "SbRespRefineMenuFilterCategory_itemSearch" | "SbRespRefineMenuFilterDimensions_itemSearch" | "SbRespRefineMenuFilterPrice_itemSearch" | "SbRespRefineMenuFilterStoneCut_itemSearch" | "SbSharedRefineMenuFilterArtOrientation_itemSearch" | "SbSharedRefineMenuFilterArtSubject_itemSearch" | "SbSharedRefineMenuFilterAvailability_itemSearch" | "SbSharedRefineMenuFilterColor_itemSearch" | "SbSharedRefineMenuFilterCreator_itemSearch" | "SbSharedRefineMenuFilterDeliveryOptions_itemSearch" | "SbSharedRefineMenuFilterFramingOld_itemSearch" | "SbSharedRefineMenuFilterFraming_itemSearch" | "SbSharedRefineMenuFilterGender_itemSearch" | "SbSharedRefineMenuFilterItemLocation_itemSearch" | "SbSharedRefineMenuFilterItemType_itemSearch" | "SbSharedRefineMenuFilterJewelryType_itemSearch" | "SbSharedRefineMenuFilterMatchingSets_itemSearch" | "SbSharedRefineMenuFilterMaterial_itemSearch" | "SbSharedRefineMenuFilterMetal_itemSearch" | "SbSharedRefineMenuFilterMovement_itemSearch" | "SbSharedRefineMenuFilterOrigin_itemSearch" | "SbSharedRefineMenuFilterPeriod_itemSearch" | "SbSharedRefineMenuFilterRingSize_itemSearch" | "SbSharedRefineMenuFilterStone_itemSearch" | "SbSharedRefineMenuFilterStyle_itemSearch" | "useMeasurementUnit_itemSearch">;
  readonly " $fragmentType": "SbSharedRefineMenuFilters_itemSearch";
};
export type SbSharedRefineMenuFilters_itemSearch$key = {
  readonly " $data"?: SbSharedRefineMenuFilters_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuFilters_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedRefineMenuFilters_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespRefineMenuFilterPrice_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilterStone_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilterMetal_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilterColor_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileRefineMenuFilterPrice_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilterStyle_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilterArtSubject_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilterOrigin_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilterPeriod_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespRefineMenuFilterCategory_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespRefineMenuFilterStoneCut_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilterCreator_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileRefineMenuFilterStoneCut_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileRefineMenuFilterCategory_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilterMaterial_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilterItemLocation_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilterDeliveryOptions_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilterItemType_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilterJewelryType_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespRefineMenuFilterDimensions_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespRefineMenuFilterCaratWeight_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileRefineMenuFilterDimensions_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileRefineMenuFilterCaratWeight_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilterAvailability_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilterMatchingSets_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilterGender_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilterFraming_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilterFramingOld_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilterRingSize_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileRefineMenuFilterArtSize_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespRefineMenuFilterArtSize_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilterArtOrientation_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilterMovement_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMeasurementUnit_itemSearch"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "topCategoryL1",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchBrowseParsedParam",
      "kind": "LinkedField",
      "name": "appliedFilters",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchBrowseParsedParamValue",
          "kind": "LinkedField",
          "name": "values",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "urlLabel",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "7dfd4dc53236a3cd10550801db921b93";

export default node;
