import { useId } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { MEASUREMENT_UNIT } from '../sbSharedRefineMenuConstants';
import { isDimensionFilter } from '../sbSharedRefineMenuHelpers';

// components
import { SbSharedRefineMenuInputRange } from './SbSharedRefineMenuInputRange';
import { SbSharedRefineMenuDimensionFacet } from '../SbSharedRefineMenuDimensionFacet/SbSharedRefineMenuDimensionFacet';
import { useMeasurementUnit } from '../../useMeasurementUnit';
import { SbSharedRefineMenuDimensionUnitRadioSelect } from '../SbSharedRefineMenuDimensionUnitRadioSelect/SbSharedRefineMenuDimensionUnitRadioSelect';

export const SbSharedRefineMenuInputRangeDimensionsComponent = props => {
    const { user, size, filters, applyOnBlur, itemSearch, showInvalidRangeErrorMessage } = props;
    const dimensionFilters = filters.filter(dimensionFilter =>
        isDimensionFilter(dimensionFilter.name)
    );
    const [measurementUnit] = useMeasurementUnit({ user, itemSearch });
    const htmlIdBase = useId();
    return (
        <>
            <SbSharedRefineMenuDimensionUnitRadioSelect
                user={user}
                itemSearch={itemSearch}
                selectedMeasurementUnit={measurementUnit}
            />
            {dimensionFilters.map(dimensionFilter => {
                const { localizedFilterName, name } = dimensionFilter;
                const id = `${htmlIdBase}-${name}`;
                return (
                    <SbSharedRefineMenuDimensionFacet
                        id={id}
                        key={name}
                        title={localizedFilterName}
                    >
                        <SbSharedRefineMenuInputRange
                            ariaDescribedBy={id}
                            size={size}
                            filterName={name}
                            filter={dimensionFilter}
                            applyOnBlur={applyOnBlur}
                            statKey={measurementUnit}
                            additionalFilter={MEASUREMENT_UNIT}
                            itemSearch={itemSearch}
                            showInvalidRangeErrorMessage={showInvalidRangeErrorMessage}
                        />
                    </SbSharedRefineMenuDimensionFacet>
                );
            })}
        </>
    );
};

SbSharedRefineMenuInputRangeDimensionsComponent.propTypes = {
    user: PropTypes.object,
    applyOnBlur: PropTypes.bool,
    filters: PropTypes.array.isRequired,
    itemSearch: PropTypes.object.isRequired,
    showInvalidRangeErrorMessage: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export const SbSharedRefineMenuInputRangeDimensions = createFragmentContainer(
    SbSharedRefineMenuInputRangeDimensionsComponent,
    {
        itemSearch: graphql`
            fragment SbSharedRefineMenuInputRangeDimensions_itemSearch on ItemSearchQueryConnection {
                ...SbSharedRefineMenuInputRange_itemSearch
                ...useMeasurementUnit_itemSearch
                ...SbSharedRefineMenuDimensionUnitRadioSelect_itemSearch
            }
        `,
        filters: graphql`
            fragment SbSharedRefineMenuInputRangeDimensions_filters on SearchBrowseFilter
            @relay(plural: true) {
                name
                localizedFilterName
                values {
                    urlLabel
                    linkReference
                    stats {
                        key
                        values
                    }
                }
            }
        `,
        user: graphql`
            fragment SbSharedRefineMenuInputRangeDimensions_user on User {
                ...useMeasurementUnit_user
                ...SbSharedRefineMenuDimensionUnitRadioSelect_user
            }
        `,
    }
);
