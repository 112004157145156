/**
 * @generated SignedSource<<de859c21caddf24bddc2c16adb049009>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuInputRangeDimensions_itemSearch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuDimensionUnitRadioSelect_itemSearch" | "SbSharedRefineMenuInputRange_itemSearch" | "useMeasurementUnit_itemSearch">;
  readonly " $fragmentType": "SbSharedRefineMenuInputRangeDimensions_itemSearch";
};
export type SbSharedRefineMenuInputRangeDimensions_itemSearch$key = {
  readonly " $data"?: SbSharedRefineMenuInputRangeDimensions_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuInputRangeDimensions_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedRefineMenuInputRangeDimensions_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuInputRange_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMeasurementUnit_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuDimensionUnitRadioSelect_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "bdc4a205bad288e8ebc2c803070050d5";

export default node;
