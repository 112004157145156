import { createContext, ReactNode, FC, useContext } from 'react';

export type CheckoutType = 'modal' | 'page';

type CheckoutServerVarsContext = {
    isMobile: boolean;
    isMultiItemCheckout: boolean;
    checkoutType: CheckoutType;
    closeCheckoutModal?: () => void;
};

export const CheckoutServerVarsContext = createContext<CheckoutServerVarsContext>({
    isMobile: false,
    isMultiItemCheckout: false,
    checkoutType: 'page',
    closeCheckoutModal: () => {},
});

type Props = {
    children: ReactNode;
    isMobile: boolean;
    isMultiItemCheckout?: boolean;
    checkoutType: CheckoutType;
    closeCheckoutModal?: CheckoutServerVarsContext['closeCheckoutModal'];
};

export const CheckoutServerVarsContextProvider: FC<Props> = ({
    children,
    isMobile,
    isMultiItemCheckout = false,
    checkoutType,
    closeCheckoutModal,
}) => (
    <CheckoutServerVarsContext.Provider
        value={{
            isMobile,
            checkoutType,
            isMultiItemCheckout,
            closeCheckoutModal,
        }}
    >
        {children}
    </CheckoutServerVarsContext.Provider>
);

export const useCheckoutServerVars = (): CheckoutServerVarsContext => {
    return useContext(CheckoutServerVarsContext);
};
