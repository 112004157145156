/**
 * @generated SignedSource<<3357c13e30257d07d7b28f2161eab602>>
 * @relayHash bee56bcf1322fe1ed7eb1f8554322142
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-finding/494.0.0-2025-01-15T20:27:27.721Z/buyRespRootQuery

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PageDisplayEnum = "auctionsSearchAndBrowse" | "cart" | "catalog" | "checkout" | "email" | "favorites" | "homepage" | "mc" | "pdp" | "publication" | "searchAndBrowse" | "similarSold" | "visuallySimilar" | "%future added value";
export type types = "DESIGNER" | "SEARCH" | "SEARCH_HISTORY" | "SELLER" | "USER_PREFERENCE" | "%future added value";
export type buyRespRootQuery$variables = {
  fetchRegionalInfo?: boolean | null;
  fetchUser: boolean;
  first: number;
  followSearchPages?: ReadonlyArray<string | null> | null;
  followSearchTypes?: ReadonlyArray<types | null> | null;
  guestId?: string | null;
  isClient: boolean;
  isTrade: boolean;
  localeOrigin: string;
  page: number;
  pageDisplayEnum?: PageDisplayEnum | null;
  personalizationId?: string | null;
  priceBookName?: string | null;
  regions?: string | null;
  regionsList?: ReadonlyArray<string | null> | null;
  rerankGuestId?: string | null;
  rerankUserId?: string | null;
  rootId: string;
  showSeller: boolean;
  uriRef: string;
  userCountryCode?: string | null;
  userId: string;
  userZipCode: string;
};
export type buyRespRootQuery$data = {
  readonly viewer: {
    readonly buyPage: {
      readonly pageExists: boolean | null;
      readonly redirect: {
        readonly location: string | null;
      } | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"BuyRespLayout_viewer">;
  };
};
export type buyRespRootQuery = {
  response: buyRespRootQuery$data;
  variables: buyRespRootQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "fetchRegionalInfo"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fetchUser"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "followSearchPages"
},
v4 = {
  "defaultValue": [
    "SEARCH",
    "USER_PREFERENCE"
  ],
  "kind": "LocalArgument",
  "name": "followSearchTypes"
},
v5 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "guestId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isClient"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isTrade"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "localeOrigin"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v10 = {
  "defaultValue": "searchAndBrowse",
  "kind": "LocalArgument",
  "name": "pageDisplayEnum"
},
v11 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "personalizationId"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "priceBookName"
},
v13 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "regions"
},
v14 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "regionsList"
},
v15 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "rerankGuestId"
},
v16 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "rerankUserId"
},
v17 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "rootId"
},
v18 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "showSeller"
},
v19 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "uriRef"
},
v20 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userCountryCode"
},
v21 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v22 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userZipCode"
},
v23 = {
  "kind": "Variable",
  "name": "priceBookName",
  "variableName": "priceBookName"
},
v24 = {
  "kind": "Variable",
  "name": "uriRef",
  "variableName": "uriRef"
},
v25 = {
  "kind": "Variable",
  "name": "userCountryCode",
  "variableName": "userCountryCode"
},
v26 = [
  {
    "kind": "Variable",
    "name": "buyPageId",
    "variableName": "rootId"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "guestId",
    "variableName": "guestId"
  },
  {
    "kind": "Variable",
    "name": "localeOrigin",
    "variableName": "localeOrigin"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  (v23/*: any*/),
  {
    "kind": "Variable",
    "name": "regions",
    "variableName": "regions"
  },
  {
    "kind": "Variable",
    "name": "regionsList",
    "variableName": "regionsList"
  },
  (v24/*: any*/),
  (v25/*: any*/),
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "personalizationId"
  }
],
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pageExists",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "concreteType": "redirectType",
  "kind": "LinkedField",
  "name": "redirect",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalResults",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlLabel",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "linkReference",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hexCode",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "linkable",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mm",
  "storageKey": null
},
v39 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "us",
  "storageKey": null
},
v40 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "localizedFilterName",
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v42 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v43 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v44 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v45 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v46 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
},
v47 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isLinkable",
  "storageKey": null
},
v48 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "anchorText",
  "storageKey": null
},
v49 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v50 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v51 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v52 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ecommerceTrackingParams",
  "storageKey": null
},
v53 = {
  "alias": null,
  "args": null,
  "concreteType": "Seller",
  "kind": "LinkedField",
  "name": "seller",
  "plural": false,
  "selections": [
    (v51/*: any*/),
    (v50/*: any*/)
  ],
  "storageKey": null
},
v54 = {
  "kind": "Variable",
  "name": "isTrade",
  "variableName": "isTrade"
},
v55 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v56 = {
  "alias": null,
  "args": null,
  "concreteType": "ConvertedAmountList",
  "kind": "LinkedField",
  "name": "convertedAmountList",
  "plural": true,
  "selections": [
    (v55/*: any*/),
    (v44/*: any*/)
  ],
  "storageKey": null
},
v57 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amountType",
  "storageKey": null
},
v58 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "textType",
  "storageKey": null
},
v59 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "categoryCode",
  "storageKey": null
},
v60 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "browseUrl",
  "storageKey": null
},
v61 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contemporaryTrackingString",
  "storageKey": null
},
v62 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isNewListing",
  "storageKey": null
},
v63 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vertical",
  "storageKey": null
},
v64 = {
  "alias": null,
  "args": null,
  "concreteType": "ShipmentQuote",
  "kind": "LinkedField",
  "name": "shipmentQuotes",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isComplimentary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCalculated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FinancialAmount",
      "kind": "LinkedField",
      "name": "totalAmount",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ConvertedAmounts",
          "kind": "LinkedField",
          "name": "convertedAmounts",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "USD",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ShipmentServiceMethod",
      "kind": "LinkedField",
      "name": "serviceMethod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Region",
          "kind": "LinkedField",
          "name": "region",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "regionName",
              "storageKey": null
            },
            (v50/*: any*/)
          ],
          "storageKey": null
        },
        (v50/*: any*/)
      ],
      "storageKey": null
    },
    (v50/*: any*/)
  ],
  "storageKey": null
},
v65 = {
  "alias": null,
  "args": null,
  "concreteType": "pdpMetaItems",
  "kind": "LinkedField",
  "name": "pdpMeta",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "topQuery",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v66 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "localizedPdpUrl",
  "storageKey": null
},
v67 = {
  "alias": null,
  "args": null,
  "concreteType": "ItemClassification",
  "kind": "LinkedField",
  "name": "classification",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creationDate",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v68 = {
  "alias": null,
  "args": null,
  "concreteType": "ItemCreator",
  "kind": "LinkedField",
  "name": "creators",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "attribution",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Creator",
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        (v31/*: any*/),
        (v50/*: any*/)
      ],
      "storageKey": null
    },
    (v50/*: any*/)
  ],
  "storageKey": null
},
v69 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isArt",
  "storageKey": null
},
v70 = {
  "alias": null,
  "args": null,
  "concreteType": "LinkData",
  "kind": "LinkedField",
  "name": "linkData",
  "plural": false,
  "selections": [
    (v46/*: any*/),
    (v47/*: any*/)
  ],
  "storageKey": null
},
v71 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isStorefrontOnly",
  "storageKey": null
},
v72 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isMultiSku",
  "storageKey": null
},
v73 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isRingItem",
  "storageKey": null
},
v74 = {
  "alias": null,
  "args": null,
  "concreteType": "VariableAttribute",
  "kind": "LinkedField",
  "name": "variableAttributes",
  "plural": true,
  "selections": [
    (v51/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "VariableAttributeSortType",
      "kind": "LinkedField",
      "name": "variableAttributeData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SwatchSort",
          "kind": "LinkedField",
          "name": "swatchSort",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Swatch",
              "kind": "LinkedField",
              "name": "swatch",
              "plural": false,
              "selections": [
                (v51/*: any*/),
                (v31/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "imageUrl",
                  "storageKey": null
                },
                (v50/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v50/*: any*/)
  ],
  "storageKey": null
},
v75 = {
  "kind": "Literal",
  "name": "page",
  "value": "searchAndBrowse"
},
v76 = [
  (v56/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "quantityDisplay",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "percentageOff",
    "storageKey": null
  },
  (v58/*: any*/),
  (v57/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "showPriceLabel",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "showPriceVariability",
    "storageKey": null
  }
],
v77 = {
  "alias": null,
  "args": [
    (v54/*: any*/),
    (v75/*: any*/),
    (v23/*: any*/)
  ],
  "concreteType": "ItemDisplayPriceType",
  "kind": "LinkedField",
  "name": "displayPrice",
  "plural": true,
  "selections": (v76/*: any*/),
  "storageKey": null
},
v78 = [
  {
    "kind": "Literal",
    "name": "formatType",
    "value": "MOBILE"
  }
],
v79 = {
  "alias": null,
  "args": null,
  "concreteType": "QuickViewDisplayType",
  "kind": "LinkedField",
  "name": "quickViewDisplay",
  "plural": false,
  "selections": [
    (v41/*: any*/),
    {
      "alias": "mobileTitle",
      "args": (v78/*: any*/),
      "kind": "ScalarField",
      "name": "title",
      "storageKey": "title(formatType:\"MOBILE\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuickViewParagraph",
      "kind": "LinkedField",
      "name": "paragraphs",
      "plural": true,
      "selections": [
        (v43/*: any*/),
        (v45/*: any*/),
        {
          "alias": "mobileText",
          "args": (v78/*: any*/),
          "kind": "ScalarField",
          "name": "text",
          "storageKey": "text(formatType:\"MOBILE\")"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuickViewCreator",
      "kind": "LinkedField",
      "name": "creators",
      "plural": true,
      "selections": [
        (v31/*: any*/),
        (v70/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuickViewCategorySegments",
      "kind": "LinkedField",
      "name": "paragraphAttributeNames",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "period",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "origin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "style",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "periodPrefix",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v80 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v81 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unit",
    "storageKey": null
  },
  (v80/*: any*/)
],
v82 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "addLabel",
      "value": true
    },
    {
      "kind": "Literal",
      "name": "fields",
      "value": [
        "height",
        "width",
        "depth",
        "diameter",
        "length"
      ]
    },
    {
      "kind": "Literal",
      "name": "separator",
      "value": " "
    }
  ],
  "concreteType": "ItemDisplayMeasurementType",
  "kind": "LinkedField",
  "name": "display",
  "plural": true,
  "selections": (v81/*: any*/),
  "storageKey": "display(addLabel:true,fields:[\"height\",\"width\",\"depth\",\"diameter\",\"length\"],separator:\" \")"
},
v83 = {
  "alias": null,
  "args": null,
  "concreteType": "ItemSize",
  "kind": "LinkedField",
  "name": "size",
  "plural": false,
  "selections": [
    (v80/*: any*/)
  ],
  "storageKey": null
},
v84 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "width",
  "storageKey": null
},
v85 = {
  "kind": "Literal",
  "name": "addLabel",
  "value": false
},
v86 = {
  "alias": null,
  "args": null,
  "concreteType": "ItemMeasurement",
  "kind": "LinkedField",
  "name": "measurement",
  "plural": false,
  "selections": [
    (v82/*: any*/),
    (v83/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "unit",
          "value": "IN"
        }
      ],
      "concreteType": "ItemConvertedMeasurementsType",
      "kind": "LinkedField",
      "name": "convertedMeasurements",
      "plural": false,
      "selections": [
        (v84/*: any*/)
      ],
      "storageKey": "convertedMeasurements(unit:\"IN\")"
    },
    {
      "alias": "widthDimensions",
      "args": [
        (v85/*: any*/),
        {
          "kind": "Literal",
          "name": "fields",
          "value": [
            "width"
          ]
        }
      ],
      "concreteType": "ItemDisplayMeasurementType",
      "kind": "LinkedField",
      "name": "display",
      "plural": true,
      "selections": (v81/*: any*/),
      "storageKey": "display(addLabel:false,fields:[\"width\"])"
    },
    {
      "alias": "heightDimensions",
      "args": [
        (v85/*: any*/),
        {
          "kind": "Literal",
          "name": "fields",
          "value": [
            "height"
          ]
        }
      ],
      "concreteType": "ItemDisplayMeasurementType",
      "kind": "LinkedField",
      "name": "display",
      "plural": true,
      "selections": (v81/*: any*/),
      "storageKey": "display(addLabel:false,fields:[\"height\"])"
    }
  ],
  "storageKey": null
},
v87 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v88 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "placeholder",
  "storageKey": null
},
v89 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "smallPath",
  "storageKey": null
},
v90 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "masterOrZoomPath",
  "storageKey": null
},
v91 = {
  "alias": "carouselPhotos",
  "args": [
    {
      "kind": "Literal",
      "name": "limit",
      "value": 2
    }
  ],
  "concreteType": "ItemPhoto",
  "kind": "LinkedField",
  "name": "photos",
  "plural": true,
  "selections": [
    (v87/*: any*/),
    (v50/*: any*/),
    (v88/*: any*/),
    (v89/*: any*/),
    (v90/*: any*/)
  ],
  "storageKey": "photos(limit:2)"
},
v92 = [
  {
    "kind": "Literal",
    "name": "limit",
    "value": 1
  }
],
v93 = {
  "alias": null,
  "args": (v92/*: any*/),
  "concreteType": "viewInRoomPhotos",
  "kind": "LinkedField",
  "name": "viewInRoomPhotos",
  "plural": true,
  "selections": [
    (v87/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "src",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "viewInRoomPhotosCenterPosition",
      "kind": "LinkedField",
      "name": "centerPosition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "top",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "left",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "viewInRoomPhotosComparatorWidth",
      "kind": "LinkedField",
      "name": "comparatorWidth",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pixel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "inches",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "viewInRoomPhotos(limit:1)"
},
v94 = {
  "alias": null,
  "args": null,
  "concreteType": "ItemPhotoVersion",
  "kind": "LinkedField",
  "name": "versions",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "webPath",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v95 = {
  "condition": "showSeller",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Seller",
      "kind": "LinkedField",
      "name": "seller",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SellerProfile",
          "kind": "LinkedField",
          "name": "sellerProfile",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "company",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ]
},
v96 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "searchTerm",
  "storageKey": null
},
v97 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "itemId",
  "storageKey": null
},
v98 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 28
  }
],
v99 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFurniture",
  "storageKey": null
},
v100 = {
  "alias": "productPhotos",
  "args": (v92/*: any*/),
  "concreteType": "ItemPhoto",
  "kind": "LinkedField",
  "name": "photos",
  "plural": true,
  "selections": [
    (v88/*: any*/),
    (v89/*: any*/),
    (v90/*: any*/),
    (v94/*: any*/),
    (v50/*: any*/)
  ],
  "storageKey": "photos(limit:1)"
},
v101 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "link",
  "storageKey": null
},
v102 = {
  "alias": null,
  "args": null,
  "concreteType": "LinkData",
  "kind": "LinkedField",
  "name": "linkData",
  "plural": false,
  "selections": [
    (v46/*: any*/),
    (v45/*: any*/),
    (v47/*: any*/)
  ],
  "storageKey": null
},
v103 = {
  "kind": "Variable",
  "name": "countryCode",
  "variableName": "userCountryCode"
},
v104 = {
  "kind": "Variable",
  "name": "zipCode",
  "variableName": "userZipCode"
},
v105 = [
  (v103/*: any*/),
  (v104/*: any*/)
],
v106 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "RegionsByZipCode",
    "kind": "LinkedField",
    "name": "regionsByZipCode",
    "plural": true,
    "selections": [
      (v31/*: any*/)
    ],
    "storageKey": null
  },
  (v50/*: any*/)
],
v107 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "zipCode",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "country",
    "storageKey": null
  },
  (v50/*: any*/)
],
v108 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  },
  {
    "kind": "Variable",
    "name": "pages",
    "variableName": "followSearchPages"
  },
  {
    "kind": "Variable",
    "name": "types",
    "variableName": "followSearchTypes"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v15/*: any*/),
      (v16/*: any*/),
      (v17/*: any*/),
      (v18/*: any*/),
      (v19/*: any*/),
      (v20/*: any*/),
      (v21/*: any*/),
      (v22/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "buyRespRootQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BuyRespLayout_viewer"
          },
          {
            "alias": "buyPage",
            "args": (v26/*: any*/),
            "concreteType": "ItemSearchQueryConnection",
            "kind": "LinkedField",
            "name": "itemSearch",
            "plural": false,
            "selections": [
              (v27/*: any*/),
              (v28/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v19/*: any*/),
      (v8/*: any*/),
      (v7/*: any*/),
      (v18/*: any*/),
      (v2/*: any*/),
      (v9/*: any*/),
      (v17/*: any*/),
      (v0/*: any*/),
      (v22/*: any*/),
      (v20/*: any*/),
      (v1/*: any*/),
      (v21/*: any*/),
      (v11/*: any*/),
      (v5/*: any*/),
      (v16/*: any*/),
      (v15/*: any*/),
      (v6/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v10/*: any*/)
    ],
    "kind": "Operation",
    "name": "buyRespRootQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "isWordpressArticlesEnabled",
            "args": [
              {
                "kind": "Literal",
                "name": "featureName",
                "value": "WP_EDITORIAL"
              }
            ],
            "kind": "ScalarField",
            "name": "isEnabledByLocale",
            "storageKey": "isEnabledByLocale(featureName:\"WP_EDITORIAL\")"
          },
          {
            "alias": "isNlgContentEnabled",
            "args": [
              {
                "kind": "Literal",
                "name": "featureName",
                "value": "NLG_CONTENT"
              }
            ],
            "kind": "ScalarField",
            "name": "isEnabledByLocale",
            "storageKey": "isEnabledByLocale(featureName:\"NLG_CONTENT\")"
          },
          {
            "alias": "isRelatedBuyPagesEnabled",
            "args": [
              {
                "kind": "Literal",
                "name": "featureName",
                "value": "RELATED_BUY_PAGES"
              }
            ],
            "kind": "ScalarField",
            "name": "isEnabledByLocale",
            "storageKey": "isEnabledByLocale(featureName:\"RELATED_BUY_PAGES\")"
          },
          {
            "alias": "buyPage",
            "args": (v26/*: any*/),
            "concreteType": "ItemSearchQueryConnection",
            "kind": "LinkedField",
            "name": "itemSearch",
            "plural": false,
            "selections": [
              (v29/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayMaxNumberOfPages",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayUriRef",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SearchBrowseParsedParam",
                "kind": "LinkedField",
                "name": "appliedFilters",
                "plural": true,
                "selections": [
                  (v30/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SearchBrowseParsedParamValue",
                    "kind": "LinkedField",
                    "name": "values",
                    "plural": true,
                    "selections": [
                      (v31/*: any*/),
                      (v32/*: any*/),
                      (v33/*: any*/),
                      (v34/*: any*/),
                      (v35/*: any*/),
                      (v36/*: any*/),
                      (v37/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FilterPropertiesType",
                        "kind": "LinkedField",
                        "name": "properties",
                        "plural": false,
                        "selections": [
                          (v38/*: any*/),
                          (v39/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v40/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "canBeDismissed",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clearAllLinkReference",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "MetaData",
                "kind": "LinkedField",
                "name": "meta",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "buyPageAvailableItemCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "header",
                    "storageKey": null
                  },
                  (v41/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "topQueries",
                    "storageKey": null
                  },
                  (v42/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "canonical",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "disableIndexing",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "microdata",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "next",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "prev",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LanguageAnnotations",
                    "kind": "LinkedField",
                    "name": "languageAnnotations",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "href",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hrefLang",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SearchBrowseFilter",
                "kind": "LinkedField",
                "name": "filters",
                "plural": true,
                "selections": [
                  (v30/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  (v40/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "originalFilterName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SearchBrowseFilterValue",
                    "kind": "LinkedField",
                    "name": "values",
                    "plural": true,
                    "selections": [
                      (v36/*: any*/),
                      (v31/*: any*/),
                      (v34/*: any*/),
                      (v32/*: any*/),
                      (v37/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "stats",
                        "kind": "LinkedField",
                        "name": "stats",
                        "plural": true,
                        "selections": [
                          (v43/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "values",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v35/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FilterPropertiesType",
                        "kind": "LinkedField",
                        "name": "properties",
                        "plural": false,
                        "selections": [
                          (v38/*: any*/),
                          (v39/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "min",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "max",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v33/*: any*/),
                      (v44/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BottomModule",
                "kind": "LinkedField",
                "name": "bottomModule",
                "plural": true,
                "selections": [
                  (v41/*: any*/),
                  (v42/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "trackedAbTests",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pageType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SortType",
                "kind": "LinkedField",
                "name": "sort",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SearchBrowseSortCurrentOption",
                    "kind": "LinkedField",
                    "name": "currentOption",
                    "plural": false,
                    "selections": [
                      (v32/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SearchBrowseSortAvailableOptions",
                    "kind": "LinkedField",
                    "name": "options",
                    "plural": true,
                    "selections": [
                      (v32/*: any*/),
                      (v34/*: any*/),
                      (v31/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "appliedPersonalizationList",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "LinkData",
                "kind": "LinkedField",
                "name": "breadcrumbs",
                "plural": true,
                "selections": [
                  (v45/*: any*/),
                  (v46/*: any*/),
                  (v47/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "InternalBuyLinkType",
                "kind": "LinkedField",
                "name": "relatedBuyPages",
                "plural": true,
                "selections": [
                  (v48/*: any*/),
                  (v49/*: any*/),
                  (v50/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "LinkData",
                "kind": "LinkedField",
                "name": "popularSearchesLink",
                "plural": false,
                "selections": [
                  (v45/*: any*/),
                  (v46/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ItemSearchQueryEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ItemSearchResult",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Item",
                        "kind": "LinkedField",
                        "name": "item",
                        "plural": false,
                        "selections": [
                          (v51/*: any*/),
                          (v52/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isOnHold",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isSold",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isUnavailable",
                            "storageKey": null
                          },
                          (v41/*: any*/),
                          (v53/*: any*/),
                          {
                            "alias": "displayPriceTracking",
                            "args": [
                              (v54/*: any*/),
                              {
                                "kind": "Variable",
                                "name": "page",
                                "variableName": "pageDisplayEnum"
                              },
                              (v23/*: any*/),
                              (v25/*: any*/)
                            ],
                            "concreteType": "ItemDisplayPriceType",
                            "kind": "LinkedField",
                            "name": "displayPrice",
                            "plural": true,
                            "selections": [
                              (v56/*: any*/),
                              (v57/*: any*/),
                              (v58/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v59/*: any*/),
                          (v60/*: any*/),
                          (v61/*: any*/),
                          (v62/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "personalizationType",
                            "storageKey": null
                          },
                          (v63/*: any*/),
                          (v64/*: any*/),
                          (v65/*: any*/),
                          (v66/*: any*/),
                          (v67/*: any*/),
                          (v68/*: any*/),
                          (v69/*: any*/),
                          (v70/*: any*/),
                          (v71/*: any*/),
                          (v72/*: any*/),
                          (v73/*: any*/),
                          (v74/*: any*/),
                          (v77/*: any*/),
                          (v79/*: any*/),
                          (v86/*: any*/),
                          (v91/*: any*/),
                          (v93/*: any*/),
                          {
                            "alias": "productPhotos",
                            "args": (v92/*: any*/),
                            "concreteType": "ItemPhoto",
                            "kind": "LinkedField",
                            "name": "photos",
                            "plural": true,
                            "selections": [
                              (v89/*: any*/),
                              (v94/*: any*/),
                              (v50/*: any*/)
                            ],
                            "storageKey": "photos(limit:1)"
                          },
                          (v50/*: any*/),
                          {
                            "alias": null,
                            "args": (v92/*: any*/),
                            "concreteType": "ItemPhoto",
                            "kind": "LinkedField",
                            "name": "photos",
                            "plural": true,
                            "selections": [
                              (v94/*: any*/),
                              (v50/*: any*/)
                            ],
                            "storageKey": "photos(limit:1)"
                          },
                          (v95/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v50/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v96/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "searchRequestId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ItemSearchSponsored",
                "kind": "LinkedField",
                "name": "sponsored",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "maxAvailable",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ItemSearchSponsoredMetadata",
                    "kind": "LinkedField",
                    "name": "metadata",
                    "plural": true,
                    "selections": [
                      (v97/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "impressionTrackerLink",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "clickTrackerLink",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SearchCorrectionsType",
                "kind": "LinkedField",
                "name": "searchCorrections",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "classificationSearchTerm",
                    "storageKey": null
                  },
                  (v96/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "semanticSearchTerm",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "soldItemResults",
                "args": (v98/*: any*/),
                "concreteType": "soldResultsConnection",
                "kind": "LinkedField",
                "name": "soldResults",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "soldResultsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ItemSearchResult",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Item",
                            "kind": "LinkedField",
                            "name": "item",
                            "plural": false,
                            "selections": [
                              (v51/*: any*/),
                              (v99/*: any*/),
                              (v41/*: any*/),
                              (v65/*: any*/),
                              (v66/*: any*/),
                              (v67/*: any*/),
                              (v68/*: any*/),
                              (v69/*: any*/),
                              (v70/*: any*/),
                              (v63/*: any*/),
                              (v64/*: any*/),
                              (v71/*: any*/),
                              (v72/*: any*/),
                              (v73/*: any*/),
                              (v74/*: any*/),
                              {
                                "alias": null,
                                "args": [
                                  (v54/*: any*/),
                                  (v75/*: any*/)
                                ],
                                "concreteType": "ItemDisplayPriceType",
                                "kind": "LinkedField",
                                "name": "displayPrice",
                                "plural": true,
                                "selections": (v76/*: any*/),
                                "storageKey": null
                              },
                              (v79/*: any*/),
                              (v86/*: any*/),
                              (v100/*: any*/),
                              (v52/*: any*/),
                              (v91/*: any*/),
                              (v93/*: any*/),
                              (v62/*: any*/),
                              (v61/*: any*/),
                              (v60/*: any*/),
                              (v59/*: any*/),
                              (v53/*: any*/),
                              (v50/*: any*/),
                              (v95/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v50/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "soldResults(first:28)"
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 20
                  }
                ],
                "concreteType": "youMayAlsoLikeConnection",
                "kind": "LinkedField",
                "name": "youMayAlsoLike",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "youMayAlsoLikeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ItemSearchResult",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Item",
                            "kind": "LinkedField",
                            "name": "item",
                            "plural": false,
                            "selections": [
                              (v51/*: any*/),
                              (v52/*: any*/),
                              (v99/*: any*/),
                              (v41/*: any*/),
                              (v65/*: any*/),
                              (v66/*: any*/),
                              (v67/*: any*/),
                              (v68/*: any*/),
                              (v69/*: any*/),
                              (v70/*: any*/),
                              (v63/*: any*/),
                              (v64/*: any*/),
                              (v71/*: any*/),
                              (v72/*: any*/),
                              (v73/*: any*/),
                              (v74/*: any*/),
                              (v77/*: any*/),
                              (v79/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ItemMeasurement",
                                "kind": "LinkedField",
                                "name": "measurement",
                                "plural": false,
                                "selections": [
                                  (v82/*: any*/),
                                  (v83/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v100/*: any*/),
                              {
                                "alias": null,
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "size",
                                    "value": "thumb"
                                  }
                                ],
                                "kind": "ScalarField",
                                "name": "firstPhotoWebPath",
                                "storageKey": "firstPhotoWebPath(size:\"thumb\")"
                              },
                              (v61/*: any*/),
                              (v60/*: any*/),
                              (v59/*: any*/),
                              (v53/*: any*/),
                              (v50/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v50/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "youMayAlsoLike(first:20)"
              },
              (v42/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BuyPageFrequentlyAskedQuestionType",
                "kind": "LinkedField",
                "name": "frequentlyAskedQuestions",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "question",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "answer",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "faqHeader",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "QuestionType",
                "kind": "LinkedField",
                "name": "questionsAnswers",
                "plural": true,
                "selections": [
                  (v50/*: any*/),
                  (v49/*: any*/),
                  (v41/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "answerCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AnswerType",
                    "kind": "LinkedField",
                    "name": "answers",
                    "plural": true,
                    "selections": [
                      (v51/*: any*/),
                      (v45/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "formattedCreatedDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Seller",
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SellerPreferences",
                            "kind": "LinkedField",
                            "name": "sellerPreferences",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "label",
                                "storageKey": null
                              },
                              (v32/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "scrollLogo",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v50/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 8
                  }
                ],
                "concreteType": "WordpressArticlesType",
                "kind": "LinkedField",
                "name": "editorialArticles",
                "plural": false,
                "selections": [
                  (v29/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WordpressArticleType",
                    "kind": "LinkedField",
                    "name": "articles",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "publishedDate",
                        "storageKey": null
                      },
                      (v41/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WordpressArticleImageType",
                        "kind": "LinkedField",
                        "name": "imageUrl",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "full",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "subtitle",
                        "storageKey": null
                      },
                      (v101/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "source",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "editorialArticles(first:8)"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "InternalBuyLinkType",
                "kind": "LinkedField",
                "name": "internalBuyLinks",
                "plural": true,
                "selections": [
                  (v49/*: any*/),
                  (v48/*: any*/),
                  (v50/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clearAppliedFiltersLinkReference",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Seller",
                "kind": "LinkedField",
                "name": "seller",
                "plural": false,
                "selections": [
                  {
                    "alias": "isRewarded",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "listNames",
                        "value": [
                          "SELLER_REWARDS_1",
                          "SELLER_REWARDS_2"
                        ]
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "isAnchorListsMember",
                    "storageKey": "isAnchorListsMember(listNames:[\"SELLER_REWARDS_1\",\"SELLER_REWARDS_2\"])"
                  },
                  (v50/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "topCategoryL1",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isAttributePage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canFollowSearch",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SearchBrowseCategoryTiles",
                "kind": "LinkedField",
                "name": "categoryTiles",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rank",
                    "storageKey": null
                  },
                  (v102/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "image",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ConvertedAmountList",
                    "kind": "LinkedField",
                    "name": "convertedAmountList",
                    "plural": true,
                    "selections": [
                      (v55/*: any*/),
                      (v44/*: any*/),
                      (v102/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "trackingCreative",
                    "storageKey": null
                  },
                  (v97/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v98/*: any*/),
                "concreteType": "personalizedSearchItemsConnection",
                "kind": "LinkedField",
                "name": "personalizedSearchItems",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "personalizedSearchItemsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ItemSearchResult",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Item",
                            "kind": "LinkedField",
                            "name": "item",
                            "plural": false,
                            "selections": [
                              (v51/*: any*/),
                              (v99/*: any*/),
                              (v41/*: any*/),
                              (v65/*: any*/),
                              (v66/*: any*/),
                              (v67/*: any*/),
                              (v68/*: any*/),
                              (v69/*: any*/),
                              (v70/*: any*/),
                              (v63/*: any*/),
                              (v64/*: any*/),
                              (v71/*: any*/),
                              (v72/*: any*/),
                              (v73/*: any*/),
                              (v74/*: any*/),
                              (v77/*: any*/),
                              (v79/*: any*/),
                              (v86/*: any*/),
                              (v100/*: any*/),
                              (v52/*: any*/),
                              (v91/*: any*/),
                              (v93/*: any*/),
                              (v62/*: any*/),
                              (v61/*: any*/),
                              (v60/*: any*/),
                              (v59/*: any*/),
                              (v53/*: any*/),
                              (v50/*: any*/),
                              (v95/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v50/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "personalizedSearchItems(first:28)"
              },
              (v27/*: any*/),
              (v28/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": "video",
            "args": null,
            "concreteType": "VideoVimeo",
            "kind": "LinkedField",
            "name": "itemVideo",
            "plural": false,
            "selections": [
              {
                "alias": "videoThumb",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "pad",
                    "value": false
                  },
                  {
                    "kind": "Literal",
                    "name": "size",
                    "value": "w295h166"
                  }
                ],
                "kind": "ScalarField",
                "name": "thumbnail",
                "storageKey": "thumbnail(pad:false,size:\"w295h166\")"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "height",
                "storageKey": null
              },
              (v84/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "VideoVimeoFile",
                "kind": "LinkedField",
                "name": "fileByVideoSize",
                "plural": false,
                "selections": [
                  (v101/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "isPopularSearchesEnabled",
            "args": [
              {
                "kind": "Literal",
                "name": "feature",
                "value": "popularSearches"
              }
            ],
            "kind": "ScalarField",
            "name": "featureFlag",
            "storageKey": "featureFlag(feature:\"popularSearches\")"
          },
          (v50/*: any*/),
          {
            "condition": "isClient",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "guestId",
                    "variableName": "rerankGuestId"
                  },
                  {
                    "kind": "Variable",
                    "name": "userId",
                    "variableName": "rerankUserId"
                  }
                ],
                "kind": "ScalarField",
                "name": "isEligibleForRerank",
                "storageKey": null
              },
              {
                "alias": "locations",
                "args": [
                  (v103/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "facetLimit",
                    "value": 10
                  },
                  {
                    "kind": "Literal",
                    "name": "facetName",
                    "value": "location"
                  },
                  (v24/*: any*/),
                  (v104/*: any*/)
                ],
                "concreteType": "ItemFacetsQueryConnection",
                "kind": "LinkedField",
                "name": "itemFacetSearch",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ItemFacetsQueryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FilterValueType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v36/*: any*/),
                          (v31/*: any*/),
                          (v32/*: any*/),
                          (v34/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          },
          {
            "condition": "fetchRegionalInfo",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": "regionsInfo",
                "args": (v105/*: any*/),
                "concreteType": "RegionalInfo",
                "kind": "LinkedField",
                "name": "regionalInfo",
                "plural": true,
                "selections": (v106/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v105/*: any*/),
                "concreteType": "RegionalInfo",
                "kind": "LinkedField",
                "name": "regionalInfo",
                "plural": true,
                "selections": (v106/*: any*/),
                "storageKey": null
              }
            ]
          },
          {
            "condition": "fetchUser",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "userId",
                    "variableName": "userId"
                  }
                ],
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserPreferences",
                    "kind": "LinkedField",
                    "name": "preferences",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "measurementUnit",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TradeFirmType",
                    "kind": "LinkedField",
                    "name": "tradeFirm",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TradeFirmRewards",
                        "kind": "LinkedField",
                        "name": "rewards",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ParentProgram",
                            "kind": "LinkedField",
                            "name": "currentProgram",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "LoyaltyProgram",
                                "kind": "LinkedField",
                                "name": "programInfo",
                                "plural": false,
                                "selections": [
                                  (v33/*: any*/),
                                  (v50/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v50/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isVerifiedTrade",
                    "storageKey": null
                  },
                  (v51/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AddressBook",
                    "kind": "LinkedField",
                    "name": "addressBook",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Address",
                        "kind": "LinkedField",
                        "name": "personalAddress",
                        "plural": false,
                        "selections": (v107/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Address",
                        "kind": "LinkedField",
                        "name": "proAddress",
                        "plural": false,
                        "selections": (v107/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Address",
                        "kind": "LinkedField",
                        "name": "addressList",
                        "plural": true,
                        "selections": (v107/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": "showStrategicPartnerFilter",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "name",
                        "value": "STRATEGIC_PARTNER_FILTERING"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "hasPermission",
                    "storageKey": "hasPermission(name:\"STRATEGIC_PARTNER_FILTERING\")"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "canShowFolder",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "canShowSellerName",
                    "storageKey": null
                  },
                  {
                    "condition": "fetchUser",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": (v108/*: any*/),
                        "concreteType": "favoritesConnection",
                        "kind": "LinkedField",
                        "name": "favorites",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "favoritesEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Favorite",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v51/*: any*/),
                                  (v31/*: any*/),
                                  (v50/*: any*/),
                                  {
                                    "kind": "ClientExtension",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__id",
                                        "storageKey": null
                                      }
                                    ]
                                  },
                                  (v87/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v108/*: any*/),
                        "filters": [],
                        "handle": "connection",
                        "key": "SharedSaveSearchContext_favorites",
                        "kind": "LinkedHandle",
                        "name": "favorites"
                      }
                    ]
                  },
                  (v50/*: any*/)
                ],
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-finding/494.0.0-2025-01-15T20:27:27.721Z/buyRespRootQuery",
    "metadata": {},
    "name": "buyRespRootQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "93a238a08e1ce50f05fa255d89add56c";

export default node;
