/**
 * @generated SignedSource<<64de92f1982b76c8c07be12ba5abb641>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedTrackingFunctions_itemSearch$data = {
  readonly edges: ReadonlyArray<{
    readonly __typename: "ItemSearchQueryEdge";
  } | null> | null;
  readonly sponsored: {
    readonly items: ReadonlyArray<{
      readonly __typename: "Item";
    } | null> | null;
    readonly totalSponsoredAdsForPage: number | null;
  } | null;
  readonly trackedAbTests: ReadonlyArray<string | null> | null;
  readonly " $fragmentType": "SbSharedTrackingFunctions_itemSearch";
};
export type SbSharedTrackingFunctions_itemSearch$key = {
  readonly " $data"?: SbSharedTrackingFunctions_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedTrackingFunctions_itemSearch">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "SbSharedTrackingFunctions_itemSearch"
};

(node as any).hash = "aa6fe32e1d3e86a0bcd52a61ea59f4e9";

export default node;
